.product-tile__quickadd-panel {
    @include absolute(bottom 0 left 0);

    z-index: 4;
    width: 100%;
    background-color: $color-panel-background;
    color: $color-body-copy;
    transform: translateY(0);
    pointer-events: all;

    transition-property: opacity, visibility, transform;

    &.error {
        color: $color-error;
        position: relative;
    }

    &.set--quickadd-active {
        transition-duration: $transition-speed-normal;
        transition-timing-function: $transition-ease-bezier;
    }

    &:not(.set--quickadd-active) {
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-in;
    }

    &:not(.set--ready) {
        transform: translateY(1rem);
        @include set-invisible();
		
		@media screen and (max-width: 1025px) {
			&.set--swatch {
				transform: translateY(1rem) !important;
				opacity: 0 !important;
				visibility: hidden !important;
			}
		}
    }

    .product-tile:hover &:not(:empty) {
        @include set-visible();
        transform: translateY(0);
    }

    &.set--swatches-expanded {
        .product-tile__swatches-icon--less,
        .product-attribute__swatch.hidden {
            display: block !important; // @NK: to override .hidden
        }

        .product-tile__swatches-icon--more {
            display: none;
        }
    }

	[data-product-component="model-description"] {
		display: none;
	}

	.no-size-selected {
		&:not(.hidden) {
			@include media-query(small) {
				[data-product-component="button-no-size-text-add"] {
					display: none;
				}
		
				[data-product-component="button-no-size-text-select"] {
					display: block;
				}
			}
		}
	}


    .quickadd__footer {
        @include media-query(medium-up) {
            .product-add__container {
                min-width: auto;
            }
        }
    }

}

        .product-tile__quickadd-actions {
            display: none;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: $color-white;
            border: solid .0625rem;
            border-radius: $input-border-radius;
        }

.quickadd__notification {
    @include set-invisible;
    @include absolute(left 0 right 0 bottom 0 top 0);

    background: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 1;

    .set--state-added & {
        @include set-visible;
    }

    .icon {
        color: $color-red;
    }
}

.quick-add-trigger {
        display: none;
}

@media screen and (max-width: 1366px) { 
    .product-tile__quickadd-panel {
        border-radius: .625rem .625rem 0 0;
        padding-left: 0;
        padding-right: 0;
        top: auto;
    }

    .quick-add-trigger {
        display: block;
    }
    .product-attribute__list--quickadd {
        .product-attribute:not([data-attr-group="size"]) {
            display: none;
        }

        .product-attribute__contents {
            flex-direction: column;
            align-items: center;
            min-height: 6.25rem;
        }

            .product-attribute__anchor {
                font-size: $font-size-centi;
            }

            .product-attribute__container {
                text-align: center;
                width: 100%;

                &:not(:first-child) {
                    border-top: 1px solid $color-grey-3;
                }
            }
    }
}

@media screen and (max-width: 1025px) { 
    .product-tile__quickadd-panel {
        border-radius: .625rem .625rem 0 0;
        padding-left: 0;
        padding-right: 0;
        top: auto;
    }

	.product-add__button--no-size {
		background-color: $color-grey-28;
		border-color: $color-grey-28;
		color: $color-white;
	}

	.quickadd-panel--hidden {
		transform: translateY(1rem) !important;
    	opacity: 0 !important;
    	visibility: hidden !important;
	}

    .quick-add-trigger {
        display: block;
    }
    .product-attribute__list--quickadd {
        .product-attribute:not([data-attr-group="size"]) {
            display: none;
        }

        .product-attribute__contents {
            flex-direction: column;
            align-items: center;
            min-height: 6.25rem;
        }

            .product-attribute__anchor {
                font-size: $font-size-micro;
            }

            .product-attribute__container {
                text-align: center;
                width: 100%;

                &:not(:first-child) {
                    border-top: 1px solid $color-grey-3;
                }
            }
    }
}

.product-attribute__list--quickadd {

    .anchor--size {
        width: rem(20);
        height: rem(20);
        margin-right: rem(5);
    }

    .product-attribute:not(:last-child) {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 2rem;
    }
}
.product-tile__anchor {
    .product-add__button.add-to-cart, 
	.quick-add-trigger,
	.product-add__button.product-add__button--no-size {
        padding: 0.22rem 0.5rem;
        font-size: $font-size-centi;
		text-transform: uppercase;
		font-weight: $font-weight-normal;
		letter-spacing: 0.6px;
        min-height: 1.63rem;
    }

	.product-add__button.product-add__button--no-size {
		&:hover:not([disabled]):not(.disabled) {
			background-color: $color-primary;
			border-color: $color-primary;
			color: $color-white;
		}
	}

    .button--tertiary,
    .button--tertiary-outline {
        &:hover:not([disabled]):not(.disabled) {
            background-color: $color-tertiary;
            border-color: $color-tertiary;
        }
    }
    
    .button--secondary,
    .button--secondary-outline {
        &:hover:not([disabled]):not(.disabled):not(.set--no-hover) {
            background-color: $color-primary;
            color: $color-white;
        }
    }
}
