@import "~app_accelerator_core/components/formElements";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.form-control-label {
    .required &:after {
        content: "*";
        color: $color-black;
    }
}

/*------------------------------------*\
  #FLOATING-LABEL-FIELDS
\*------------------------------------*/
[data-floating-label] {
    position: relative;

    .form-control-label {
        display: inline-flex;
        position: absolute;
        top: 1.25rem;
        left: 0;
        transform-origin: top left;
        transition: opacity $transition-speed-faster $transition-ease-default,
            transform $transition-speed-faster $transition-ease-default;
        cursor: text;
        pointer-events: none;

        @include media-query(small-up) {
            top: 1rem;
        }
    }

    .form-control--size-small+.form-control-label {
        top: 0.5rem;

        @include media-query(small-up) {
            top: 0.5rem;
        }
    }

    &.set--floating-label {
        .form-control-label {
            transform: translateY(-2rem);

            @include media-query(small-up) {
                transform: translateY(-1.875rem);
            }
        }

        .form-control--size-small+.form-control-label {
            transform: translateY(-1.5rem);

            @include media-query(small-up) {
                transform: translateY(-1.5rem);
            }
        }
    }

    &:not(.set--floating-label) {
        .form-control--select {
            //IE doesn't like straight transparency here.
            color: transparentize($color-white, 0.99);
        }

        .form-control {
            &::placeholder {
                color: transparent;
            }

            //This seems to be required for IE.
            &:-ms-input-placeholder {
                color: transparent;
            }
        }
    }
}

.set--floating-label {
    .form-control-label--select {
        opacity: 1; //select dropdowns need this to avoid label overlay.
    }
}

/*------------------------------------*\
  #DISAPPEARING-LABEL-FIELDS
\*------------------------------------*/
[data-disappearing-label] {
    position: relative;

    .form-control-label {
        display: inline-flex;
        position: absolute;
        top: 1.25rem;
        left: 0;
        transform-origin: top left;
        transition: opacity $transition-speed-faster $transition-ease-default,
            transform $transition-speed-faster $transition-ease-default;
        cursor: text;
        pointer-events: none;

        @include media-query(small-up) {
            top: 1rem;
        }
    }

    .form-control--size-small+.form-control-label {
        top: 0.5rem;

        @include media-query(small-up) {
            top: 0.5rem;
        }
    }

    &.set--disappearing-label {
        .form-control-label {
            opacity: 0;

            @include media-query(small-up) {
                opacity: 0;
            }
        }

        .form-control--select {
            //IE doesn't like straight transparency here.
            color: transparentize($color-white, 0.99);
        }

    }

    &:not(.set--disappearing-label) {
        .form-control-label {
            transform: translateY(-2rem);

            @include media-query(small-up) {
                transform: translateY(-1.875rem);
            }
        }

        .form-control--size-small+.form-control-label {
            transform: translateY(-1.5rem);

            @include media-query(small-up) {
                transform: translateY(-1.5rem);
            }
        }

        .form-control {
            &::placeholder {
                color: transparent;
            }

            //This seems to be required for IE.
            &:-ms-input-placeholder {
                color: transparent;
            }
        }
    }
}

.form-control {
    border-width: 0 0 1px 0;
    border-color: $color-primary;
    height: $input-height--mobile;

    @include media-query(small-up) {
        min-height: $input-height--desktop;
    }

    &.is-invalid {
        border-color: $color-primary;
        background-color: transparent;
    }

    &:focus {
        box-shadow: none;
    }
}

.form-control--size-small {
    height: $input-height--mobile-small;

    @include media-query(small-up) {
        min-height: $input-height--desktop-small;
    }
}

.form-group {
    font-size: $font-size-deci;
    margin-bottom: 1.75rem;
}

[class*="form-check-label"] {
    cursor: pointer;
    padding-left: 1.6875rem;

    &::before {
        border-radius: .25rem;
        height: 1.1875rem;
        width: 1.1875rem;
    }

    &::after {
        height: 0.75rem;
        width: 0.75rem;
        top: 0.125rem;
        left: 0.25rem;
    }
}

/*
    RADIO BUTTON
*/

.form-check-label--radio,
.payment-saved__label {
    display: flex;
    position: relative;
    padding-left: 1.5rem;
    font-size: $font-size-deci;
    color: $color-grey-10;

    &::before {
        border: solid 0.0625rem $color-grey-3;
        border-radius: 100%;
        height: 1.1875rem;
        width: 1.1875rem;

    }

    &::after,
    &::before {
        content: '';
        position: absolute;
        left: 0;
    }
}

.payment-saved__label {
    margin-bottom: 0.75rem;
    font-size: $font-size-centi;
    .paymentMethod_img {
        width: 1.3125rem;
        height: 0.8125rem;
        border-radius: .175rem;
        align-self: center;
    }
}

.form-check-input:not(:checked) {

    +.form-check-label,
    +button .form-check-label {
        color: $color-grey-22;
    }
}


.form-check-input:checked+.form-check-label--radio::before,
.payment-saved__content input:checked~.payment-saved__label::before {
    background-color: transparent;
    border-color: $color-black;
}

.payment-saved__content {
    position: relative;
    display: flex;

    .payment-saved__name {
        padding-left: .5rem;
    }

    input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;

        &:checked~.payment-saved__label {
            color: $color-black;
        }
    }

    img {
        border: solid 1px $color-grey-10;
        border-radius: 0.375rem;
        margin-left: 0.5rem;
    }

    .payment-saved__number {
        margin-left: rem(8);
    }

    .payment-saved__expiration {
        margin-left: rem(20);
    }
}

.paymentMethod--scheme .payment-saved__content .payment-saved__label {
    font-size: $font-size-deci;
}

.form-check-input:checked+.form-check-label--radio::after,
.payment-saved__content input:checked~.payment-saved__label::after {
    content: '';
    transform: scale(1);
    height: 0.6875rem;
    width: 0.6875rem;
    left: 0.25rem;
    top: 0.25rem;
    background-color: $color-black;
    opacity: 1;
    border-radius: 50%;
}

.form-group select {
    font-size: 0.75rem;

    &:invalid {
        color: $color-grey-10;
    }
}

input::placeholder {
    font-size: 0.75rem;
    color: $color-grey-10;
}

.form-group {
    &.required .form-control-label::before {
        content: none !important;
    }
}

/*------------------------------------*\
  #FORM VALIDATION
\*------------------------------------*/
.success-feedback {
    color: $success-feedback;
}
.success-feedback,
.invalid-feedback,
.form-invalid-feedback {
    display: block;
    margin-top: 0.3rem;
    font-size: .75rem;
    position: relative;
    padding-top: 0;

    &:not(.set--visible) {
        position: absolute;
        @include set-invisible();
    }
}

.invalid-feedback,
.form-invalid-feedback {
    color: $color-error;

    &::after {
        display: block;
        @include absolute(right .3125rem top -2.1875rem);
    }
}