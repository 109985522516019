@import "~app_accelerator_core/base/scaffold";
html,
body {
    overscroll-behavior: none;
}

.blur-up {
    transition: filter $transition-ease-out $transition-speed-faster;

    &.lazyload,
    &.lazyloading,
    &.lazyloaded {
        filter: blur(0);
    }
}

@include media-query(medium-up) {
    body {
        padding-bottom: rem(100);
    }
}
