.slick-vertical {
    .slick-slide {
        &.promo-banner--size-small {
            height: rem(82);
        
            @include media-query(medium) {
                height: rem(64);
            }
        }
        &.promo-banner--size-regular {
            height: rem(114);
        
            @include media-query(medium) {
                height: rem(82);
            }
        }
        &.promo-banner--size-large {
            height: rem(210);
        
            @include media-query(medium) {
                height: rem(120);
            }
        }
    }
}


.promo-banner__body {
    line-height: normal;
    padding-top: rem(13);
    padding-bottom: rem(10);

    .promo-banner-title {
        line-height: 0.86;
        letter-spacing: rem(2);
        font-size: rem(10);
        font-weight: $font-weight-normal;
        @include media-query(medium-up) {
            font-size: $font-size-h5;
            letter-spacing: rem(2.8);
        }
    }
    .promo-banner-text {
        font-size: rem(12);
        letter-spacing: rem(3.6);
        font-weight: $font-weight-semibold;
        line-height: 0.6;
        line-height: normal;
        
        @include media-query(medium-up) {
            font-size: rem(20);
            letter-spacing: rem(2.8);
            letter-spacing: rem(6);
        }
    }
    .join-now,
    .details-view {
        font-size: rem(10);
        font-weight: $font-weight-medium;
        text-decoration: underline;
        line-height: 1;
        letter-spacing: rem(1.2);
        transition: all .2s;
        transform: scale(.8);
        @include media-query(medium-up) {
            font-size: $font-size-h6;
        }
        &:hover {
            transform: scale(1);
            font-size: $font-size-h5;
        }
    }
}