@import "./promoBar";

/**
 * Contains global styles for the header.
 * This stylesheet should not include component specific styles.
 */

.header-container {
    .set--header-blend & {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    }

    &.set--overlay {
        .header-promo {
            z-index: 1;
        }
    }

    &:not(.set--overlay) {
        .header-promo {
            transition: z-index 0s $transition-ease-default $transition-speed-fast;
        }
    }
}

.header {
    z-index: 6;
    position: relative;
    background-color: $header-nav-bg;
    transition-property: background-color, color, box-shadow;
    transition-duration: $transition-speed-slow;
    transition-timing-function: $transition-ease-bezier;
    box-shadow: 0 .0625rem 0 0 transparentize($color-black, .85);
    cursor: initial;

    &:not(.header--no-menu) {
        box-shadow: 0 .0625rem 0 0 transparentize($color-black, .85);
    }

    @include media-query(medium-up) {
        z-index: 1000;
    }

    .set--header-blend & {
        //Sticky display "always" vs "scroll" require different class states to determine when to display as "blended".
        //This is because the plugin will set the header as as "fixit--active" as soon as it touches the viewport's top,
        //which is not desired when using the sticky on "scroll" behavior.
        &.header--sticky-always:not(.fixit--active),
        &.header--sticky-scroll:not(.fixit--scroll-direction-change) {
            &:not(:hover):not(:focus-within):not(.focus-within) {
                background-color: transparent;
                color: $color-white;
                box-shadow: inset 0 -.0625rem 0 0 transparent;
            }
        }
    }

    //Styles for when header should diplay sticky at all times.
    &.header--sticky-always {
        &.fixit--active {
            position: fixed;
            top: 0;
            width: 100%;
        }
    }

    //Alternate styles for when the header should only display when scrolling back UP.
    &.header--sticky-scroll {
        &.fixit--active {
            position: absolute;
            width: 100%;
            //Intentionally override transition properties here to prevent jumping when header becomes un-fixed.
            transition-property: margin, background-color, color, box-shadow;

            &:not(.fixit--scroll-direction-change) {
                bottom: 0;
            }
        }

        &.fixit--scroll-direction-change {
            position: fixed;
            top: 0;
        }

        //Use FixIt's state classes to only display the navigation when scrolling up.
        //Separate margin-top is used on desktop and mobile for the hidden state.
        &.fixit--scrolled {
            &.fixit--scroll-up {
                margin-top: 0;
            }
        }
    }
}

.header__logo,
.header__hamburger-trigger  {
    color: $header-nav-color;
}

.header__logo {
    height: 1.875rem;
    //Ensures width is maintained on older browsers (IE11).
    flex-grow: 1;
    color: $color-primary;
    z-index: 2;
}

.header__logo-img {
    width: 100%;
    fill: currentColor;
}

.header__skip-to-main {
    position: absolute;
    top: 1rem;
    left: 2rem;
    z-index: 1000;

    &:not(:focus) {
        pointer-events: none;
        opacity: 0;
    }
}

.header-promo--ad {
    width: 100%;
    z-index: 5;
    transition: z-index 2s $transition-ease-default $transition-speed-fast;

    &.scroll-up {
        display: none;
    }
}

.dropdown-account-promo:has(.content-asset div:empty),
.header-promo--ad:has(.content-asset div:empty) {
    display: none;
}



.header-promo {
    position: relative;

}

.header-promo--top {
    //Prevent other contents on the page from overlapping this element.
     z-index: 3;
}

    .header__nav-secondary-item {
        margin-right: 1rem;
    }

        .header__nav-secondary-icon,
        .header__minicart-icon.icon {
            font-size: 1.75rem;
        }


@include media-query(medium-up) {
    .header {
        //Use FixIt's state classes to hide the navigation when scrolling down.
        &.fixit--scrolled {
            &.fixit--scroll-down:not(:hover):not(:focus-within):not(.focus-within) {
                //Avoid using transform to prevent some funky issues with display.
                //This is a small sacrifice, but the header will always have a standard height anyway.
                //Adjust if necessary (separate height is used in mobile).
                // transform: translateY(-100%);
                &.header--stacked {
                    margin-top: -7.5rem;
                }

                &.header--standard {
                    margin-top: -6rem;
                }
            }
        }
    }

    .header--no-menu {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .header--stacked {
        padding-top: 1.25rem;

        .header-flyout__list {
            justify-content: center;
        }
    }

    .header__logo {
        max-width: 10rem;
        flex-shrink: .8;
    }

    .header__account {
        align-items: center;
    }

    .header__content {
        @include relative;

        display: flex;
        justify-content: space-between;
    }

        .header__nav-secondary {
            @include absolute(top calc(100% + 2.5rem) right 0);

            display: flex;
            z-index: 2;
        }

            .header__nav-secondary-item {
                margin-right: 2rem;
            }
}

@include media-query(medium) {
    .header-container:not(.hidden) {
        .header.header--sticky-always {
            transition: all 0.5s;
        }
    }

    .header-container.hidden {
        display: block !important;
        height: 0;

        .header.header--sticky-always{
            height: 0;
            opacity: 0;
            z-index: 0;
        }
    }

    .header {
        box-shadow: none;

        //Use FixIt's state classes to hide the navigation when scrolling down.
        &.fixit--scrolled {
            &.fixit--scroll-down:not(:hover):not(:focus-within):not(.focus-within) {
                //Avoid using transform to prevent some funky issues with display.
                //This is a small sacrifice, but the header will always have a standard height anyway.
                //Adjust if necessary (separate height is used in mobile).
                // transform: translateY(-100%);
                margin-top: -5rem;
            }
        }

        &:not(.header--no-menu) {
            box-shadow: none;
        }
    }

    .header__main {
        padding-top: .5rem;
        padding-bottom: .5rem;
    }

    .header__content {
        @include relative;
    }

        .header__logo {
            max-width: 6.875rem;
            margin-left: 1rem;
        }

        .header__logo-container {
            display: flex;
            align-items: center;
        }

        .header__nav-secondary {
            @include absolute(top .25rem right 0);

            display: flex;
        }
}

@include media-query(small-up-to-large) {
	.header__nav-secondary-item:lang(fr) {
		margin-right: 1rem;
	}
}

[data-action="Product-Show"] {

    .header-container.fixit-container {
        .fixit-placeholder {
            @include media-query(medium) {
                display: none;
            }
        }
    }

    .header.header--sticky-always {
    
        @include media-query(medium) {
            position: relative;
        }
        
        &.fixit--active {
            @include media-query(medium) {
                position: relative;
            }
        }
    }
}

.dropdown.set--size-regular {
    .dropdown-menu {
        &.account-help {
            padding: 1rem 0;
            width: 13rem;
            .help-call-info {
                padding: 0 rem(16) rem(10);
                text-align: center;
                font-size: rem(13);
                line-height: rem(24);
                @include media-query(medium) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
                @include media-query(medium-up) {
                    border-bottom: 1px solid $color-grey-2;
                }
                .account-helper__title {
                    font-size: rem(13);
                    @include media-query(medium) {
                        display: none;
                    }
                }
                
                @include media-query(medium) {
                    .locale {
                        order: 2;
                    }
                    .account-helper__schedule {
                        order: 1;
                    }
                    .account-helper__mail {
                        order: 3;
                    }
                    .local,
                    .account-helper__schedule,
                    .account-helper__mail {
                        font-size: rem(14);
                        letter-spacing: rem(2.16);
                        padding: rem(10) rem(7) rem(10) rem(20);
                    }

                }

            }
            .account-helper__navigation {
                padding: 1rem 1.5rem;
                @include media-query(medium) {
                    text-align: center;
                }
                a {
                    @include media-query(medium) {
                        justify-content: center;
                        display: inline-block;
                        border-radius: 0;
                        text-align: center;
                        margin-bottom: 0;
                        min-width: rem(240);
                        min-height: rem(40);
                    }
                }
            }
        }
        .account-gift__title {
            font-size: $font-size-h5;
        }

        &.dropdown--subscription {
            padding: rem(24) rem(14);
        }
        .subscription-banner__form {
            display: block;
        }
        .subscription-banner__action {
            max-width: rem(175);
            min-width: rem(175);
            margin: rem(24) auto 0;
            display: block;
        }
    }
}