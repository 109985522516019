@import 'propertyMixins';

/*------------------------------------*\
  #TYPOGRAPHY
\*------------------------------------*/

// Font families
$font-family-sans   : "Source Sans Pro", Helvetica, Arial, sans-serif !default;
$font-family-sans-secondary: "Montserrat", Helvetica, Arial, sans-serif !default;
$font-family-serif  : Times, serif !default;
$font-family-cursive: cursive !default;

// Larger than headings
$font-size-yotta  : 8.875rem !default;  // 142px
$font-size-zetta  : 7.375rem !default;  // 118px
$font-size-exa    : 6.25rem !default;   // 100px

// Headings
$font-size-h1      : 5.125rem !default;  // 82px
$font-size-h2      : 4.375rem !default;  // 70px
$font-size-h3      : 3.5rem !default;    // 56px
$font-size-h4      : 3rem !default;      // 48px
$font-size-h5      : 2.5rem !default;    // 40px
$font-size-h6      : 2rem !default;      // 32px

// Body font sizes
$font-size-kilo    : 1.75rem !default; //28px
$font-size-hecto   : 1.5rem !default; //24px
$font-size-deka    : 1.25rem !default; //20px

$font-size-base    : 1rem !default; // 16px Base font size

$font-size-deci    : .875rem !default; // 14px
$font-size-centi   : .75rem !default; // 12px
$font-size-micro   : .625rem !default; // 10px


// Font Weights
$font-weight-light    : 300 !default;
$font-weight-normal   : 400 !default;
$font-weight-semibold : 600 !default;
$font-weight-bold     : 700 !default;
$font-weight-extrabold: 800 !default;

// Font Line Height

$font-line-height-mega  : 1.9 !default;
$font-line-height-kilo  : 1.75 !default;
$font-line-height-hecto : 1.63 !default;
$font-line-height-deka  : 1.5 !default;
$font-line-height-base  : 1.38 !default;
$font-line-height-deci  : 1.19 !default;
$font-line-height-centi : 1.06 !default;
$font-line-height-milli : 0.85 !default;

/*------------------------------------*\
  #COLORS
\*------------------------------------*/

$color-white: #FFF !default;
$color-black: #000 !default;

//GREYSCALE COLORS
$color-grey-1: #F6F6F8 !default;
$color-grey-2: #E6E9ED !default;
$color-grey-3: #CCCCCC !default;
$color-grey-4: #999999 !default;
$color-grey-5: #666666 !default;
$color-grey-6: #444444 !default;
$color-grey-7: #252525 !default;

$color-red: #e60000 !default;
$color-blue: #4D96E7 !default;
$color-green: #00D3A7 !default;
$color-yellow: #E7B04D !default;
$color-beige: #f5f5dc !default;

//SOCIAL MEDIA COLORS
$color-facebook-blue: #1877f2 !default;
$color-google-blue: #4285f1 !default;
$color-google-red: #ea4236 !default;
$color-instagram-pink: #C62A81 !default;
$color-instagram-orange: #F06430 !default;

$color-paypal-blue: #009cde !default;
$color-paypal-silver: #eeeeee !default;

$color-primary: #ff491c !default;
$color-secondary: #ffbb1c !default;
$color-tertiary: #ff1c60 !default;

$color-accent-primary: #0DFFCB !default;
$color-accent-secondary: #DAFF79 !default;
$color-accent-tertiary: #28BAFF !default;

//Set an empty default map, which is then used as a merge point in the case there are overrides in overlaid cartridges.
$colors: () !default;

$colors: map-merge((
    white: $color-white,
    black: $color-black,

    //GREYSCALE COLORS
    grey-1: $color-grey-1,
    grey-2: $color-grey-2,
    grey-3: $color-grey-3,
    grey-4: $color-grey-4,
    grey-5: $color-grey-5,
    grey-6: $color-grey-6,
    grey-7: $color-grey-7,

    red: $color-red,
    blue: $color-blue,
    navy: darken($color-blue, 35%),
    green: $color-green,
    beige: $color-beige,

    //SOCIAL MEDIA COLORS
    facebook-blue: $color-facebook-blue,
    google-blue: $color-google-blue,
    google-red: $color-google-red,
    instagram-pink: $color-instagram-pink,
    instagram-orange: $color-instagram-orange,

    paypal-blue: $color-paypal-blue,
    paypal-silver: $color-paypal-silver,

    primary: $color-primary,
    secondary: $color-secondary,
    tertiary: $color-tertiary,

    accent-primary: $color-accent-primary,
    accent-secondary: $color-accent-secondary,
    accent-tertiary: $color-accent-tertiary,

    error: $color-red,
    success: $color-green
), $colors);

//UI COLORS
$color-error: $color-red !default;
$color-success: $color-green !default;

$color-body-copy: $color-grey-7 !default;
$color-body-background: $color-white !default;
$color-panel-background: $color-body-background !default;
$color-input-background: $color-body-background !default;

/*------------------------------------*\
  #ELEMENT-DEFAULTS
\*------------------------------------*/
$input-border-radius: .125rem !default;
$input-height: 3.125rem !default;
$input-lateral-offset: .625rem;

/*------------------------------------*\
  #HEADER-DEFAULTS
\*------------------------------------*/
$header-nav-bg: var(--header-nav-bg, $color-body-background) !default;
$header-nav-color: var(--header-nav-color, 'currentColor') !default;
$header-nav-panel-bg: var(--header-nav-panel-bg, $color-body-background) !default;
$header-nav-panel-color: var(--header-nav-panel-color, 'currentColor') !default;

/*------------------------------------*\
  #HORIZONTAL-SPACING
\*------------------------------------*/

$gutter-inner: .625rem !default; //base
$gutter-inner-deci: .35rem !default;

$gutter-outer-kilo: 3.125rem !default;
$gutter-outer: 2rem !default; //base
$gutter-outer-deci: 1.25rem !default;
$gutter-outer-centi: 1rem !default;
$gutter-outer-micro: .75rem !default;

/*------------------------------------*\
  #BREAKPOINTS
\*------------------------------------*/

$breakpoints: () !default;

// **** If this is updated, make sure FEDConstants.js is also updated. ***
$breakpoints: map-merge((
    unit           : 0.0625rem, // 1
    smallest       : 20rem, // 320
    xsmallest      : 30rem, // 480
    small          : 47.9375rem, // 767
    xsmall         : 56rem, // 896
    medium         : 64rem, // 1,024
    xmedium        : 77.5rem, // 1,240
    large          : 90rem, // 1,440
    xlarge         : 102.5rem // 1,640
), $breakpoints);


/*------------------------------------*\
  #TRANSITIONS
\*------------------------------------*/

// Transition durations
$transition-speed-slower    :   1s !default;
$transition-speed-slow      :   600ms !default;
$transition-speed-mid-slow  :   500ms !default;
$transition-speed-normal    :   426ms !default;
$transition-speed-medium    :   320ms !default;
$transition-speed-fast      :   213ms !default;
$transition-speed-faster    :   107ms !default;
$transition-speed-fastest   :   54ms !default;

// Transition easing
$transition-ease-default    :   ease-in-out !default;
$transition-ease-in         :   ease-in !default;
$transition-ease-out        :   ease-out !default;
$transition-ease-bezier     :   cubic-bezier(.4,.9,.3,1) !default;
$transition-ease-bounce     :   cubic-bezier(.4,.9,.3,1.25) !default;
$transition-ease-big-bounce :   cubic-bezier(.4,.9,.3,2.25) !default;

