.product__shop-similar {
    @include absolute(top 1rem left .75rem);

    background: rgba(255, 255, 255, .3);
    padding: .35rem .5rem .35rem 1.25rem;
    border-radius: .125rem;
    overflow: hidden;
    font-weight: $font-weight-medium;
    font-size: $font-size-micro;
    text-transform: uppercase;
    letter-spacing: $font-spacing--medium;
    line-height: 1.25;
    z-index: 1;

    &:hover {
        background: rgba(255, 255, 255, .75);
    }

    .icon {
        @include absolute(top -.1875rem left -.3125rem);

        font-size: rem(36);
    }

    .product-tile__media & {
        @include set-invisible();
    }

    .set--state-swipeable & {
        @include set-visible();

        left: .25rem;
    }
}

.product__complete-your-look{
    display: none;
}

@include media-query(medium-up) {
    .product-tile__media:hover {
        .product__shop-similar {
            @include set-visible();
        }
    }
}

.product__complete-your-look {
    @include absolute(bottom -1.75rem right 1.7rem);
    display: block;
    width: 4.5rem;
    height: 3.125rem;
    padding: 0.5625rem 0.575rem 0.475rem;
    opacity: 0.5;
    border-radius: 0.625rem;
    background-color: #000;
    font-size: $font-size-micro;
    z-index: 1;
    color: $color-white;
    text-align: center;
    text-transform: uppercase;
}


@include media-query(medium-up) {
    .product__complete-your-look {
        @include absolute(bottom 23% left -2.3125rem);
    }
}

