
.breadcrumbs {
    margin-bottom: 1rem;
}

.breadcrumbs--spaced {
    margin-top: 1rem;
}

    .breadcrumbs__item {
        &:not(:last-child) {
            margin-right: 1em;
        }
    }

    .breadcrumbs__separator {
        display: inline-block;
        margin-left: 1em;
    }