
.product-attribute__anchor {
    position: relative;
    border: .0625rem solid transparent;
    padding: 0;
    font-size: $font-size-centi;
    border-radius: 100%;
    height: 1.75rem;
    line-height: 1;
    width: 1.75rem;

    transition-property: box-shadow, color, background-color;
    transition-duration: $transition-speed-fast;
    transition-timing-function: $transition-ease-default;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        pointer-events: none;

        transition-property: width, height;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-default;

        content: '';
    }

    &.unselectable {
        color: $color-grey-33;
        cursor: default;

        &:after {
            width: 100%;
            height: 100%;
        }
    }

    &.selected {
        border-color: $color-body-copy;
        color: $color-primary;
    }
}
