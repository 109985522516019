.product-tile--default {
    position: relative;

    .product-tile__anchor {
        display: block;

        @media screen and (max-width: 1366px) {
            .product-tile__swatch,
            .product-tile__body {
                &.swatch--color {
                    pointer-events: visible;
                }
                .product-tile__name {
                    pointer-events: visible;
                }
            }
            .quick-add-trigger,
            .product-tile__swatch {
                pointer-events: visible;
            }
        }
    }

    .product-tile__media-container,
    .product-tile__badges,
    .product__shop-similar {
        pointer-events: visible;
    }

    .product-tile__quickadd-panel {
        pointer-events: none;
        .product-attribute__swatch {
            &.swatch--color {
                pointer-events: visible;
            }
        }
        .product-tile__swatch,
        .quickadd__footer {
            pointer-events: visible;
        }
        .product-attribute__anchor {
            &.anchor--size {
                pointer-events: visible;
            }
        }
    }

    .product-tile__body {
        padding-top: 3.25rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.product-tile--card {
    position: relative;
    margin-bottom: 1.25rem;

    .product-tile__body {
        flex: 1 1 70%;
        padding-right: .5rem;
        padding-left: .5rem;
    }

    .product-tile__media {
        display: block;
        flex: 1 1 30%;

        @include aspect-ratio(1, 1, true);
    }
}

.product-tile--overlay {
    padding: 1.25rem;
}

@include media-query(medium-up) {
    .product-tile--card {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: $gutter-outer;
        padding-left: .5rem;
    }
}

@include media-query(medium) {
    .product-tile--card {
        padding: 1.25rem;

        .product-tile__footer {
            margin-top: 1.25rem;
        }
    }
}
