@import "~app_accelerator_core/helpers/variables";

// 0. Breakpoints (If this is updated, make sure frontendConstants.js is also updated.)

$breakpoints: (
    unit           : 0.0625rem, // 1
    smallest       : 20rem, // 320
    xsmallest      : 30rem, // 480
    small          : 47.9375rem, // 767
    medium         : 60rem, // 960
    xmedium        : 77.5rem, // 1,240
    large          : 90rem, // 1,440
    xlarge         : 120rem // 1,920
);

// 1. Colors:

$color-primary: #000000;
$color-tertiary: #ff6d81;
$color-blue: #4D96E7;
$color-red: #A4402F;
$color-red-1: #b23727;
$color-red-2: #c60606;
$color-red-3: #c1281b;
$color-red-4: #FF0000;
$color-green: #048B18;
$color-green-2: #DEE5DA;
$color-green-3: #207700;
$color-green-4: #009110;
$color-body-copy: $color-primary;
$color-grey-1: #F0F0F0;
$color-grey-2: #C9C9C9;
$color-grey-3: #CCCCCC;
$color-grey-4: #969696;
$color-grey-5: #888888;
$color-grey-6: #EFEFEF;
$color-grey-7: #ACACAC;
$color-grey-8: #F8F8F8;
$color-grey-9: #F7F7F7;
$color-grey-10: #919191;
$color-grey-11: #adadad;
$color-grey-12: #C1C1C1;
$color-grey-13: #ededed;
$color-grey-14: #CECECE;
$color-grey-15: #D7DEE366;
$color-grey-16: #9A9A9A;
$color-primary-transparent: #00000029;
$color-primary-transparent-2: #00000063;
$color-pink: #FE6D81;
$color-light-grey: #dddddd;
$color-dark-grey: #5a5a5a;

$color-grey-17: #928989;
$color-grey-18: #f3f4f5;
$color-grey-19: #8e8e8e;
$color-grey-20: #b3b3b3;
$color-grey-21: #666666;
$color-grey-22: #757575;
$color-grey-23: #F3F4F5;
$color-grey-24: #f6f6f6;
$color-grey-25: #e7e6e2;
$color-grey-26: #767676;
$color-grey-27: #ebebeb;
$color-grey-28: #878787;
$color-grey-29: #4b4b4b;
$color-grey-31: #707070;
$color-grey-30: #b2b2b2;
$color-grey-32: #a8a8a8;
$color-grey-33: #e0dbdb;
//rewards colors

$color-rewards-1: #ebeae3;
$color-rewards-2: #f8e8ea;
$color-rewards-3: #e1e3e7;
$color-rewards-4: #f8e7ea;
$color-rewards-5: #edece7;
$color-rewards-6: #f1f3f5;
$color-rewards-7: #fef8fb;
$color-rewards-8: #f2f2f3;
$color-rewards-9: #1d2b2c;
$color-rewards-10: #fbf8f5;
$color-rewards-11: #ebeae4;

//email-success
$success-feedback: #169E50;

// 2. Fonts:

$font-path: '../fonts/';
$font-spacing--static: .36px;
$font-spacing--large: .1em;
$font-spacing--medium: .05em;
$font-family-sans: "Graphik", Helvetica, Arial, sans-serif;
$font-family-secondary: 'DIN Condensed', Helvetica, Arial, sans-serif;
$font-family-primary: $font-family-sans;
$font-family-sans-secondary: $font-family-sans;

    // 2.1. Font weight

    $font-weight-normal: 400;
    $font-weight-medium: 500;
    $font-weight-semibold: 600;
    $font-weight-bold: 700;

    // 2.2. Larger than headings

    $font-size-yotta  : 4.375rem;  // 70px
    $font-size-zetta  : 3.125rem;  // 50px
    $font-size-exa    : 2.25rem;   // 36px

    // 2.3. Headings

    $font-size-h1: 1.875rem;  // 30px
    $font-size-h2: 1.375rem;  // 22px
    $font-size-h3: 1.125rem;  // 18px
    $font-size-h4: 1rem;      // 16px
    $font-size-h5: 0.875rem;  // 14px
    $font-size-h6: 0.75rem;   // 12px


// 3. UI configs:

$input-border-radius: 0;
$input-lateral-offset: 0;
$input-height--desktop: 2.5rem;
$input-height--mobile: 2.8125rem;
$input-height--mobile-small: 1.875rem;
$input-height--desktop-small: 1.875rem;

// 4. Sizes map

$sizes: (
    0: 0,
    3: .1875rem,
    5: .3125rem,
    10: .625rem,
    14: .875rem,
    16: 1rem,
    20: 1.25rem,
    24: 1.5rem,
    30: 1.875rem,
    36: 2.25rem,
    40: 2.5rem,
    50: 3.125rem,
    60: 3.75rem,
    72: 4.5rem,
    'auto': auto
);

// 5. Skeleton

$skeleton-element-color: rgba(0, 0, 0, 0.17);
$skeleton-loading-animation-time: 1.3s;
$skeleton-margin-standart: 1rem;
$skeleton-wrapper-padding: $skeleton-margin-standart;
$skeleton-margin-small: calc($skeleton-margin-standart / 2) ;
$skeleton-card-background-color: $color-white;
$skeleton-card-border-radius: .375rem;
$skeleton-circle-size: 3.125rem;
$skeleton-hr-height: 2px;
$skeleton-line-border-radius: 1rem;
$skeleton-line-height: $font-size-deci;
$skeleton-headline-height: 2rem;
$skeleton-square-height: 25rem;
