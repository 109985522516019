@import "~app_accelerator_core/components/toggleBox";

.toggle-box--arrow {
    .toggle-box__label:after {
        @include absolute(top rem(-5));

        content: "";
        display: inline-block;
        width: rem(10);
        height: rem(10);
        border-right: .0625rem solid $color-primary;
        border-top: .0625rem solid $color-primary;
        transform: rotate(135deg);
        transition: transform .4s;
    }

    .toggle-box__label,
    .toggle-box__content {
        font-size: rem(12);

        @include media-query(small-up) {
            padding: 0 rem(15);
        }
    }

    .toggle-box__label {
        &.toggle--active {
            &:after {
                transform: rotate(-45deg);
                transition: transform .5s;
            }
        }
    }
}
