.experience-component {
    .module-container__header, .module-container__header--content {
        display: flex;
        align-items: baseline;

        .module-container__title {
            font-family: $font-family-secondary;
            font-size: $font-size-zetta;
            text-transform: uppercase;
            padding-right: 2rem;
            margin-left: rem(60);
        }
        .module-container__description {
            font-size: $font-size-hecto;
            border-left: 1px solid black;
            padding-left: 2rem;
        }
        .module-container__cta {
            text-align: right;
            font-size: rem(18);
            text-decoration: underline;
        }
    }

    .module-container__header {
        .experience-accelerator_components-cta {
            margin-left: auto;
            margin-right: auto;
        }
    }

    .experience-accelerator_assets-moduleDescription {
        width: 100%;
    }

    .module-container__cta {
        margin-left: auto;
        margin-right: rem(65);
    }
}

@include media-query(small) {
    .experience-component {
        .module-container__header, .module-container__header--content {
            .module-container__title {
                font-size: rem(26);
                margin-left: rem(24);
                padding-right: rem(14);    
            }
        
            .module-container__description {
                font-size: $font-size-base;
                padding-left: rem(14);
            }
        }
        .module-container__header--secondary {
            display: flex;
            flex-direction: column;

            .experience-accelerator_components-cta {
                margin-top: rem(20);
            }
        }
    }
}

