
.swatch--color {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 0 0 0 transparent;
    background-color: currentColor;
    border: solid .0625rem transparentize($color-black, 0.75);
    border-radius: 50%;
    transition-property: box-shadow;
    transition-duration: $transition-speed-fast;
    transition-timing-function: $transition-ease-default;

    &:after {
        @include vertical-align($alignHorizontal: true, $posAdj: -.125rem);
        border-radius: 50%;
        opacity: 0;

        transition-property: opacity;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-bezier;

        content: '';
    }

    &:not(.disabled):hover, &.selected {
        &:after {
            opacity: 1;
            box-shadow: 0 0 0 rem(2) white, 0 0 0 rem(3) $color-black;
        }
    }

    &.disabled, &.unselectable {
        opacity: 0.2;
        border-color: transparentize($color-black, 0.25);
        cursor: not-allowed;
    }

    &.selected {
        &:before {
            border-radius: 50%;
            background-color: transparentize($color-black, 0.5);
            content: '';
        }
    }
}

    .swatch__icon--color {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

.swatch--color-small {
    width: 1.25em;
    height: 1.25em;
    flex: 0 0 1.25em;

    &:after {
        box-shadow: inset 0 0 0 .125rem $color-white;
    }

    &.selected {
        &:before {
            width: 1rem;
        }
    }
}

.swatch--color-large {
    width: 2em;
    height: 2em;
    flex: 0 0 2em;

    &:after {
        box-shadow: inset 0 0 0 .25rem $color-white;
    }

    &.selected {
        &:before {
            width: 1rem;
            height: 1rem;
        }
    }
}

.swatch--color {
    @include property-modifiers($colors, background-color, '-');
}
