.product-tile__badges {
    @include absolute(bottom 0 left 0 right 0);

    pointer-events: none;
    padding: .75rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

    .product-tile-badge {
        background: rgba(0, 0, 0, 0.3);
        border-radius: .125rem;
        color: $color-white;
        text-transform: uppercase;
        font-size: $font-size-micro;
        font-weight: $font-weight-medium;
        letter-spacing: .5px;
        padding: .25rem .35rem;
        margin: 0 .75rem;
        line-height: 1;
    }

@include media-query(medium-up) {
    .product-tile__badges {
        padding: 1.25rem;
    }

        .product-tile-badge {
            padding: .5rem .65rem;
        }
}
