
/*------------------------------------*\
  #INPUT-RESETS
\*------------------------------------*/

[type="password"],
[type="text"],
[type="tel"],
[type="email"],
[type="search"],
[type="number"],
select,
textarea {
    color: $color-body-copy;
    font-family: inherit;
    font-weight: $font-weight-light;
    appearance: none;
    border-radius: $input-border-radius;
    border: solid .0625rem $color-grey-4;
    outline: none;

    &::placeholder {
        color: $color-grey-4;
    }

    &:focus {
        &::placeholder {
            color: $color-grey-3;
        }
    }

    &.disabled, &[disabled] {
        color: $color-grey-4;
    }

    &:not([disabled]):not([readonly]):not(.is-invalid):focus {
        border-color: $color-grey-7;
    }
}

input[type="file"] {
    cursor: pointer;

    &.disabled, &[disabled] {
        cursor: not-allowed;
    }
}

//Moves Safari's autofill icon to the left so that it doesn't
//overlay the tooltip icon.
input::-webkit-contacts-auto-fill-button {
    position: relative;
    right: 1.5rem;
}

textarea {
    display: block;
    width: 100%;
    resize: none;
}

select {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &::-ms-expand {
        display: none;
    }
}

option {
    color: $color-body-copy;
}

/*------------------------------------*\
  #FORM-COPY
\*------------------------------------*/
.form-intro__title {
    margin-bottom: .75em;
}

.form-intro__copy {
    margin-bottom: 1.75em;
}

.form-control-disclaimer {
    margin-top: .75em;
}

.form-control-description {
    margin-top: .25em;
    font-size: $font-size-deci;
    color: $color-grey-5;
}

/*------------------------------------*\
  #TEXT AND SELECTS
\*------------------------------------*/
.form-control {
    width: 100%;
    height: $input-height;
    padding-right: $input-lateral-offset;
    padding-left: $input-lateral-offset;
    background-color: $color-input-background;

    &.is-invalid {
        border-color: $color-error;
        background-color: lighten($color-error, 52%);
        color: $color-error;
    }

    &.remove--border-styles {
        border-radius: 0;
        border-color: transparent;
    }

    &.remove--border-right-styles {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

    &.remove--border-left-styles {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }
}

.form-control--small {
    height: 2.5rem;
}

//Add custom styles for text input fields.
.form-control--text {

}

.form-control--textarea {
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: auto;
    min-height: 4.5rem;
}

.custom-select,
.form-control--select {
    padding-right: 2rem;
    background-size: .85em .85em; //Provide height + width separately to fix IE display.
    background-repeat: no-repeat;
    background-position: calc(100% - .5em) center;
    background-image: url(get-icon(angle-down, $color-body-copy));
    transition: color $transition-speed-faster $transition-ease-default;

    &:-internal-autofill-selected {
        background-image: url(get-icon(angle-down, $color-body-copy)) !important;
    }
}

.form-control-label {
    display: inline-block;
    font-size: $font-size-deci;
    // font-weight: $font-weight-semibold;
    margin-bottom: .25rem;
    // color: $color-grey-4;

    .required &:after {
        content: "*";
        color: $color-error;
    }
}

.form-control-label--inline {

}

/*------------------------------------*\
  #CHECKBOXES AND RADIOS
\*------------------------------------*/
.form-check {
    position: relative;
}

.form-check-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

[class*="form-check-label"] {
    display: block;
    position: relative;
    padding-left: 1.9em;
    line-height: 1.3; //Aligns label with checkbox. Intentionally not a variable.
    transition: color $transition-speed-fast $transition-ease-bezier;

    &:before, &:after {
        position: absolute;
    }

    &:before {
        top: 0;
        left: 0;
        border: solid .0625rem $color-grey-5;
        border-radius: $input-border-radius;
        content: '';
        width: 1.25em;
        height: 1.25em;
    }

    &:after {
        top: .125em;
        left: .125em;
        width: 1em;
        height: 1em;
    }
}

.form-check-label {
    &:after {
        opacity: 0;
        line-height: $font-line-height-centi;
        transition: opacity $transition-speed-fast $transition-ease-bezier;
        content: url(get-icon(checkmark, $color-primary));
    }
}

.form-check-label--radio {
    &:before, &:after {
        border-radius: 50%;
    }

    &:after {
        background-color: $color-primary;
        transform: scale(0);
        transform-origin: center;
        transition: transform $transition-speed-fast $transition-ease-bezier;
        content: '';
    }
}

.form-check-input:checked + .form-check-label,
.form-check-label.set--checked,
.form-check-label.toggle--active {
    color: inherit;

    &:before {
        background-color: $color-primary;
        border-color: $color-primary;
    }

    &:after {
        opacity: 1;
        transform: scale(1);
        content: url(get-icon(checkmark, $color-white));
    }
}

.form-check-input:checked + .form-check-label--radio,
.form-check-label--radio.set--checked,
.form-check-label--radio.toggle--active {
    &:after {
        opacity: 1;
        background-color: $color-primary;
        transform: scale(.75);
    }
}

.form-check-input:focus + [class*="form-check-label"],
[class*="form-check-label"]:focus {
    outline: none;

    &:before {
        border-color: $color-primary;
    }
}

.form-check-input:disabled + [class*="form-check-label"],
[class*="form-check-label"].disabled {
    &:before {
        background-color: $color-grey-1;
        border-color: $color-grey-2;
    }

    &:after {
        color: $color-grey-2;
    }
}

.form-check-input.error + [class*="form-check-label"],
.form-check-input.is-invalid + [class*="form-check-label"] {
    &:before {
        border-color: $color-error;
    }
}

/*------------------------------------*\
  #FORM GROUPS
\*------------------------------------*/
.form-group {
    margin-bottom: 1.25rem;
}

.form-group--no-spacing {
    margin-bottom: 0;
}

.form-check-list__item {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

//Use this to separate entire sections within a form or groups of forms.
.form-section {
    &:not(:first-child) {
        margin-top: 1.6rem;
    }
}

/*------------------------------------*\
  #FORM ACTIONS
\*------------------------------------*/

//Ensure this class is added on a block element, so that bottom/top  margins are collapsed.
.form-actions {
    margin-top: 2rem;
}

/*------------------------------------*\
  #FORM VALIDATION
\*------------------------------------*/
.success-feedback,
.invalid-feedback,
.form-invalid-feedback {
    display: block;
    margin-top: .25rem;
    font-size: $font-size-deci;

    &:not(.set--visible) {
        position: absolute;
        @include set-invisible();
    }
}

.invalid-feedback,
.form-invalid-feedback {
    color: $color-error;
}

.success-feedback {
    color: $color-success;
}

.form-invalid-feedback {
    margin-bottom: 1em;
}

/*------------------------------------*\
  #FORM CONSTRAINTS
\*------------------------------------*/

.form-control__constraints {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: .75em;
    color: $color-grey-5;
}

    .form-control__constraint {
        flex: 1 1 50%;
    }

    .set--constraint-fail {
        color: $color-error;
    }

    .set--constraint-pass {
        color: $color-success;
    }

/*------------------------------------*\
  #FLOATING-LABEL-FIELDS
\*------------------------------------*/
//UNCOMMENT IF USING FLOATING LABELS
// [data-floating-label] {
//     position: relative;

//     .form-control-label {
//         display: inline-flex;
//         position: absolute;
//         top: .5125rem;
//         left: 0;
//         transform-origin: top left;
//         transition: opacity $transition-speed-faster $transition-ease-default,
//                     transform $transition-speed-faster $transition-ease-default;
//         cursor: text;
//         pointer-events: none;
//     }

//     &.set--floating-label {
//         .form-control-label {
//             transform: translateY(-1.5125rem);
//         }
//     }

//     &:not(.set--floating-label) {
//         .form-control--select {
//             //IE doesn't like straight transparency here.
//             color: transparentize($color-white, 0.99);
//         }

//         .form-control {
//             &::placeholder {
//                 color: transparent;
//             }

//             //This seems to be required for IE.
//             &:-ms-input-placeholder {
//                 color: transparent;
//             }
//         }
//     }
// }

// .set--floating-label {
//     .form-control-label--select {
//         opacity: 1; //select dropdowns need this to avoid label overlay.
//     }
// }

@media (-ms-high-contrast: active) {
    .form-check-label {
        &:after {
            content: url(get-icon(checkmark, $color-white));
        }
    }

    .form-check-label--radio {
        &:after {
            border: solid .5rem;
        }
    }
}

@include media-query(small) {
    input,
    select,
    textarea {
        //Intentionally set to 1rem to force input fields have exactly 16px
        //font size, which prevents zooming in when focusing them on mobile.
        font-size: 1rem;
    }
}
