@import "~app_accelerator_core/modules/htmlBlock";

.html-block__title-wrapper {
    display: flex;
    align-items: end;
    flex-wrap: nowrap;
    margin-bottom: rem(25);

    .html-block__title {
        margin-left: rem(30);
        max-width: rem(290);
        line-height: 1.25;

        @include media-query(medium) {
            margin-left: rem(10);
            max-width: rem(200);
        }
    }
}

    .html-block__title-icon {
        max-width: rem(71);
        margin-bottom: rem(4);

        @include media-query(medium) {
            max-width: rem(45);
        }
    }
