/*------------------------------------*\
  #BUTTONS
\*------------------------------------*/

button {
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
    font: inherit;
}

.button {
    //For non-button tags:
    display: inline-block;
    padding: 0.5rem;
    border: solid .0625rem;
    border-radius: $input-border-radius;
    min-height: $input-height--mobile;
    font-size: $font-size-deci;
    font-weight: $font-weight-medium;
    text-align: center;
    transition: opacity $transition-speed-normal $transition-ease-bezier,
        color $transition-speed-normal $transition-ease-bezier,
        border-color $transition-speed-normal $transition-ease-bezier,
        background-color $transition-speed-normal $transition-ease-bezier;

    @include media-query(small-up) {
        min-height: $input-height--desktop;
    }

    &.remove--border-left-radius {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.remove--border-right-radius {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &[disabled],
    &.disabled {
        background-color: $color-grey-28;
        border-color: $color-grey-28;
        color: $color-white;
        cursor: not-allowed;
    }
}

.button--round {
    border-radius: 0.375rem;
}

.button--small {
    font-size: $font-size-centi;
    font-weight: $font-weight-semibold;
    min-height: $input-height--mobile-small;
    text-transform: uppercase;

    @include media-query(small-up) {
        min-height: $input-height--desktop-small;
    }
}

//Necessary override to avoid some difficult choices.
.button--flex {
    display: flex;
}

//Allows the contents of the button to define its width.
//Helpful when the button's default padding is too large.
.button--fluid {
    padding-right: 0;
    padding-left: 0;
}

.button--primary,
.button--primary-outline {
    background-color: $color-white;
    border-color: $color-primary;
    color: $color-primary;

    &:hover:not([disabled]):not(.disabled) {
        background-color: $color-primary;
        color: $color-white;
    }
}

.button--secondary,
.button--secondary-outline {
    background-color: $color-primary;
    border-color: $color-primary;
    color: $color-white;

    &:hover:not([disabled]):not(.disabled):not(.set--no-hover) {
        background-color: $color-white;
        color: $color-primary;
    }
}

.button--tertiary,
.button--tertiary-outline {
    background-color: $color-tertiary;
    border-color: $color-tertiary;
    color: $color-white;

    &:hover:not([disabled]):not(.disabled) {
        background-color: $color-primary;
        border-color: $color-primary;
        color: $color-white;
    }
}

.button--tertiary-black-outline {
    background-color: $color-tertiary;
    border-color: $color-primary;
    color: $color-white;

    &:hover:not([disabled]):not(.disabled) {
        border-color: $color-tertiary;
        color: $color-white;
    }
}

.button--cross-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 2.5em;
    height: 2.5em;
    transition: opacity $transition-speed-normal $transition-ease-bezier;

    &:hover,
    &:focus {
        opacity: .5;
    }
}

.button--paypal {
    overflow: hidden;
    display: block;
    position: relative;
    height: 3rem;
    padding: 0;
    background-image: url('../images/payment-logos/PayPal_logo.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 6.2rem;

    //Aligns the paypal button by using an absurd width
    //to force the API to add a large height.
    .paypal-button {
        @include vertical-align(true, true);
        //PayPal adds width: 100%; on a randomly generated element ID.
        width: 100% !important;
        height: 100% !important;
        opacity: 0.000000001; //For some reason, Chrome doesn't let click throughs into an iframe if set to 0.

        //This is a necessary evil to force
        //PayPal button container to not use a fixed height
        .xcomponent-outlet,
        .zoid-outlet,
        .zoid-component-outlet {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

//SFCC's Apple Pay buttons require some extra specifity.
.button--apple-pay.dw-apple-pay-button {

    &,
    &:hover,
    &:active {
        margin: 0;
        height: 3rem;
        border-radius: $input-border-radius;
    }
}

/*------------------------------------*\
  #CHIPS
\*------------------------------------*/

.chip {
    display: inline-block;
    width: 1.75rem;
    height: 1.75rem;
    color: $color-primary;
    padding: 0;
    padding-top: 0.3125rem;
    border: solid .0625rem $color-grey-4;
    border-radius: 100%;
    text-align: center;
    overflow: hidden;
    font-size: $font-size-centi;

    &:hover,
    &.selected {
        border-color: $color-primary;
        font-weight: $font-weight-medium;
    }
}

/*------------------------------------*\
  #PILLS
\*------------------------------------*/

.pill {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: $font-size-base;
}

.pill--selected {
    color: $color-white;
    background: $color-primary;
    border: solid $color-primary;
    font-weight: $font-weight-medium;
    border-radius: .3rem;
    padding: .35em 1em;
}

.pill--selected:hover {
    color: $color-primary;
    background: $color-white;
}

.pill--disabled {
    background: $color-grey-3;
    border-color: $color-grey-3;
    color: $color-white;
    pointer-events: none;
    font-weight: $font-weight-medium;
    border-radius: .3rem;
    padding: .35em 1em;
}

.pill--badge {
    border: solid $color-grey-3;
    font-weight: $font-weight-medium;
    border-radius: .3rem;
    padding: .35em 1em;
}

.pill--badge:hover {
    color: $color-white;
    background: $color-primary;
    border-color: $color-primary;

    .pill__icon {
        color: $color-white;
    }
}

.pill__icon {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100%;

    .pill__icon--swatch & {
        background: black;
        margin-bottom: .65rem;
    }
}

.pill--icon-left {
    flex-direction: row;
    padding-left: .35em;
}

.pill--icon-right {
    flex-direction: row;
    padding-right: .5em;
}

.pill__icon--actionable {
    &:hover {
        opacity: .5;
    }
}

.pill__icon--swatch:hover {
    font-weight: $font-weight-medium;

    .pill__icon::before {
        border: solid $color-primary;
        position: absolute;
        left: -0.25rem;
        right: -0.25rem;
        ;
        top: -0.25rem;
        ;
        bottom: -0.25rem;
        ;
        background: none;
        content: "";
        border-radius: 100%;
    }
}

.pill__icon--left {
    margin-right: .5em;
}

.pill__icon--right {
    margin-left: 1em;
}
