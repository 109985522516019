.margin--auto {
    margin-left: auto;
    margin-right: auto;
}

.margin {
    @include property-modifiers($sizes, margin);
}

.margin-l {
    @include property-modifiers($sizes, margin-left);
}

.margin-t {
    @include property-modifiers($sizes, margin-top);
}

.margin-r {
    @include property-modifiers($sizes, margin-right);
}

.margin-b {
    @include property-modifiers($sizes, margin-bottom);
}

.padding {
    @include property-modifiers($sizes, padding);
}

.padding-l {
    @include property-modifiers($sizes, padding-left);
}

.padding-t {
    @include property-modifiers($sizes, padding-top);
}

.padding-r {
    @include property-modifiers($sizes, padding-right);
}
.padding-b {
    @include property-modifiers($sizes, padding-bottom);
}

@include media-query(medium-up) {
    .d-margin--auto {
        margin-left: auto;
        margin-right: auto;
    }

    .d-margin {
        @include property-modifiers($sizes, margin);
    }

    .d-margin-l {
        @include property-modifiers($sizes, margin-left);
    }

    .d-margin-t {
        @include property-modifiers($sizes, margin-top);
    }

    .d-margin-r {
        @include property-modifiers($sizes, margin-right);
    }

    .d-margin-b {
        @include property-modifiers($sizes, margin-bottom);
    }

    .d-padding {
        @include property-modifiers($sizes, padding);
    }

    .d-padding-l {
        @include property-modifiers($sizes, padding-left);
    }

    .d-padding-t {
        @include property-modifiers($sizes, padding-top);
    }

    .d-padding-r {
        @include property-modifiers($sizes, padding-right);
    }

    .d-padding-b {
        @include property-modifiers($sizes, padding-bottom);
    }
}

.no-padding {
    padding: 0 !important;
}
