@import "~app_accelerator_core/components/header/headerMinicart";

.header__minicart {
    .check-icon {
        display: none;
    }
}


// .header__minicart {
//     &.toggle--active {
//         .header__utility-badge {
//             display: none;
//         }
//         .check-icon {
//             display: block;
//             position: absolute;
//             top: 40%;
//             left: 50%;
//             -webkit-transform: translateX(-50%);
//             transform: translateX(-50%);
//             min-width: 1rem;
//             height: 1rem;
//             padding-right: 0.25em;
//             padding-left: 0.25em;
//             border-radius: 1rem;
//             font-size: 0.5625rem;
//             font-weight: 700;
//          }

//     }
// }

.header__minicart-overlay {
    &.utility-overlay {
        border-radius: rem(6);
        overflow: hidden;
        border: rem(.5) solid $color-grey-3;

        &.toggle--active {
            transition: none;
        }

        @include media-query(medium) {
            right: rem(20);
            left: rem(20);
            bottom: rem(20);
            width: auto;
            top: rem(20);
            height: auto;

            &.toggle--active {
                display: none !important; // @NK: to override visibility rules
            }
        }

        @include media-query(medium-up) {
            position: absolute;
            top: auto;
            margin-top: rem(36);
            display: table;

            &.toggle--active {
                transition: transform $transition-speed-normal $transition-ease-bezier,
                            visibility 0s;
            }
        }
    }

    .utility-overlay__line-items {
        @include media-query(medium-up) {
            min-height: rem(235);
            max-height: rem(340);
            overflow: auto;

            &.utility-overlay__line-items--small {
                min-height: rem(38.35);
                max-height: rem(200);
            }

            &.utility-overlay__line-items--x-small {
                min-height: rem(12);
                max-height: rem(34);
            }
        }
    }

    .utility-overlay__header {
        padding: 0;
        .approaching-discounts__item {
            font-size: 0.75rem;
            text-align: center;
            padding: rem(13.6) rem(11.1) rem(12.8) rem(5.9);
            border-bottom: rem(1) solid $color-light-grey;
            letter-spacing: 0.36;

        }
    }

    .utility-overlay__heading {
        padding: rem(16px) 0;
        background-color: $color-grey-13;
        position: relative;
    }

    .utility-overlay__header-close {
        @include media-query(medium-up) {
            display: none;
        }
    }

    .product-line-item--minicart {
         padding: 0 rem(17px) rem(18px);

         &:first-child {
            margin-top: rem(18px);
         }

        .product-line-item__details.row {
            margin-left: 0.3125rem;
            margin-right: 2.3125rem;
            .product-line-item__promotions-sale {
                color: $color-red-1;
                font-size: $font-size-h6;
                padding-left: rem(10);
                font-weight: $font-weight-medium;
            }
        }

        .product-line-item__qty-input {
            pointer-events: none;
            border: none;
        }

        .product-line-item__qty-form {
            display: flex;
        }

        .product-line-item__header,
        .product-line-item__attributes {
            margin-left: 0.5rem;
        }

        .product-line-item__attributes {
            .product-line-item__qty-pricing {
                display: block;
                margin: 0;
                .minicart-promo {
                    display: flex;
                    .form-control-label {
                        display: inline-block;
                        color: $color-grey-10;
                        font-size: rem(12);
                        min-width: rem(50);
                        font-weight: normal;
                    }
                    .product-line-item__promotions {
                        font-size: rem(12);
                        color: $color-red-1;
                        font-weight: $font-weight-medium;
                    }
                }
                &.minicart-price-item {
                    display: flex;
                    flex-direction: column;

                    .product-line-item__quantity {
                        order: 1;
                    }

                    .product-line-item__unit-price {
                        order: 2;
                    }
                    .line-item-promo {
                        &.minicart-promo {
                            order: 3;
                        }
                    }
                    .product-line-item__total-price {
                        order: 4;
                        .price__original {
                            font-size: $font-size-h5;
                        }
                    }
                }
            }
            .product-line-item__attribute {
                .product-line-item__promotions {
                    display: none;
                }
            }
        }

        .product-line-item__attribute {
            display: flex;
        }

        .product-line-item__total-price {
            display: none;
        }

        .product-line-item__name {
            font-size: rem(12px);
            display: block;
        }

        .form-control-label {
            margin-bottom: 0;
        }

        .product-line-item__attribute:not(:last-child) {
            margin-bottom: 0;
        }

        .product-line-item__style {
            font-size: rem(12px);
        }

        .product-line-item__attribute-key {
            font-size: rem(12px);
        }

        .product-line-item__attribute-value {
            font-size: rem(12px);
        }

        .product-line-item__attribute-key,
        .product-line-item__qty-label,
        .line-item-pricing-info,
        .line-item-pricing-info .form-control-label,
        .product-line-item__style {
            display: inline-block;
            color: $color-grey-10;
            font-size: rem(12px);
            min-width: 50px;
            font-weight: normal;
        }

        .product-line-item__attribute-value,
        .line-item-total-price-amount,
        .price .value,
        .product-line-item__qty-input {
            font-size: rem(12px);
        }

        .product-line-item__qty-input {
            pointer-events: none;
            border: none;
            background-image: none;
            min-height: auto;
            width: auto;
            padding: 0;
            height: auto;
        }

        .sales-price {
            color: $color-red;
            font-weight: 500;
        }

        .previous-price {
            text-decoration: line-through;
        }

        .product-line-item__qty-pricing,
        .qty-selector,
        .product-line-item__actions,
        .product-line-item__availability,
        .product-line-item__style {
            display: none;
        }

    }

    .checkout-buttons__minicart {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .checkout-buttons__item {
            flex: 0 0 48%;
            margin-top: rem(8);

            .adyen-checkout__applepay__button {
                height: rem(30);
                margin: rem(-0.5);
                border-radius: rem(4);
            }

            .checkout-buttons__item {
                margin: 0;
            }
        }

        .minicart__checkout-action {
            text-transform: none;
            border-radius: rem(4);
            font-weight: $font-weight-medium;
            max-height: rem(35);
            line-height: 1;
        }

        .afterpay-checkout-button,
        .gpay-card-info-container,
        .gpay-button-fill > .gpay-button.white {
            border-radius: rem(4);
            outline: none;
            min-width: 100%;
            min-height: rem(35);
            max-height: rem(35);
        }

        .gpay-button-fill > .gpay-button.white {
            padding: rem(8);
            border: 1px solid $color-primary;
            min-height: rem(30);
            max-height: rem(30);
        }
    }

    .utility-overlay__header-title {
        font-size: rem(16px);
        text-align: center;
        width: 100%;
    }

    .utility-overlay__footer-actions {
        margin-top: 0;
    }


    .utility-overlay__footer-subtotal {
       margin-left: 0.625rem;
    }

    --messaging-font-size: 0.75rem;
    --logo-badge-width: 4.625rem;

    .product-added-notification {
        display: none;
    }

    &.set--state-added {
        .product-added-notification {
            display: block;
            position: fixed;
            z-index: 9;
            width: auto;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                background-color: $color-primary;
                opacity: 0.5;
            }

            @include media-query(medium-up) {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            .product-added-notification__info {
                min-width: 188px;
                min-height: 161px;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                background-color: $color-grey-13;
                padding: 40px 30px 40px;
                text-align: center;
                border-radius: 6px;

                @include media-query(medium-up) {
                    min-width: auto;
                    min-height: auto;
                    left: 0;
                    right: 0;
                    transform: none;
                    top: 0;
                    bottom: 0;
                    padding: 0;
                    border-radius: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: row-reverse;
                }

                .bag-icon {
                    position: relative;
                    display: inline-block;

                    .header__minicart-icon {
                        font-size: rem(50px);

                        @include media-query(medium-up) {
                            display: none;
                        }
                    }

                    .check-icon {
                        position: absolute;
                        left: 50%;
                        top: 55%;
                        transform: translate(-50%,-50%);
                    }
                }

                .product-added-notification__msg {

                }
            }



            .icon {
                @include media-query(medium-up) {
                    color: $color-tertiary;
                    margin-left: 3rem;
                }
            }
        }
    }

    .cart__error-message {
        padding: 0 1.0625rem;
    }

    .cart__shipping-message {
        font-size: 0.75rem;
        text-align: center;
        padding: rem(13px) rem(11px) rem(13px) rem(6px);
        letter-spacing: 0.36px;

        .cart__shipping-info {
            color: $color-green;
            font-weight: 600;
        }
    }
    .utility-overlay__footer-message {
        color: $color-grey-10;
        margin: rem(16) 0;
    }

    .afterpay-logo.brand-afterpay {
        height: auto;
        vertical-align: bottom;
    }
}

.paypal-buttons {
    & + & {
        display: none !important;
    }
}
