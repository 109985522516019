.product-tile__media--default {
    display: block;

    @include aspect-ratio(1, 1.5, true);
}

    .product-tile__image {
        max-height: none;
        transition-property: opacity, visibility;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-in;
    }

    .product-tile__media-container {
        overflow: hidden;
        display: flex;
        flex-wrap: nowrap;

        .product-tile__image {
            flex-shrink: 0;
        }
    }

.product-tile__image-previous,
.product-tile__image-next {
    @include absolute(right 0 top 50%);

    transform: translateY(-50%);
    padding: .75rem .5rem;

    .icon {
        height: 1.25rem;
        width: 1.25rem;
    }
}

.product-tile__image-previous {
    @include set-invisible();
    left: 0;
    right: auto;
}

@include media-query(medium) {
    .set--state-swipeable {
        .product-tile__image-previous {
            @include set-visible();
        }
    }

    .set--single-image {
        .product-tile__image-next {
            @include set-invisible();
        }
    }
}

@include media-query(medium-up) {
    .product-tile__image-previous,
    .product-tile__image-next {
        @include set-invisible();
    }

    .product-tile__media-container:not(.set--single-image):hover {
        .product-tile__image-previous,
        .product-tile__image-next {
            @include set-visible();
        }
    }
}

.product-list__carousel {
    .slick-list {
        @include media-query(medium) {
            padding:0 0 0 20% !important;
        }
    }

    .slick-slide:has(.hide-product) {
        display: none;
    }
}