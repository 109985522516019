.dropdown {
    /*------------------------------------*\
    # MY ACCOUNT DROPDOWN
    \*------------------------------------*/
    &.set--size-regular {
        .dropdown-menu.dropdown--account {
            padding: 0;
        }
    }

    .dropdown--account__rewards {
        font-weight: $font-weight-semibold;
        letter-spacing: $font-spacing--medium;
        padding: 0.75rem 0.5625rem;
        border-bottom: 0.0313rem solid $color-grey-4;
    }
    
    .dropdown--account__header {
        font-weight: $font-weight-semibold;
        margin-bottom: 0.8rem;
    }

    .dropdown--account__content {
        padding: 1rem 1.5rem 0.2rem
    }

    .dropdown--account__nav-item {
        margin-bottom: 0.8rem;
        span {
            @include media-query(medium-up) {
                max-width: rem(124);
            }
        }

    }

    .dropdown--account__nav-icon {
        margin: 0 0.5rem 0.375rem 0;
        width: rem(14);
        height: rem(15);

        &.icon__profile {
            fill: none;
            stroke: $color-black;
        }

        &.icon__myCommPrefs {
            width: 1.2rem;
            @include media-query(medium-up) {
                width: rem(20);
                height: rem(15);
            }
            height: 1rem;
        }
    }

    /*------------------------------------*\
    # LOGIN DROPDOWN
    \*------------------------------------*/
    .dropdown-menu.dropdown--login.toggle--active {
        padding: 1.25rem;
        overflow: hidden;
    }

    .dropdown-login__subtitle {
        letter-spacing: 0.0225rem;
        &.error {
            padding-bottom: rem(40);
        }
    }

    .login-form .form-group{
        &.flex {
            letter-spacing: 0.0225rem;
        }

        &.required {
            flex-grow: 1;
            width: 100%;
            
            &:last-of-type {
                margin-bottom: 0;
            }
            
            .form-control {
                min-height: 0;
                max-height: 1.5rem;
                letter-spacing: 0.0187rem;
                font-size: $font-size-centi;
            }
        }
    }
    
    .password-trigger {
        @include relative(left 16.56rem bottom 1.13rem);
        
        .password-trigger__icon {
            height: 0.8125rem;
            width: 0.8125rem;
        }
        
        + .invalid-feedback  {
            bottom: 1.2rem;
        }
    }
    
    .form-control-label {
        width: 100%;
        font-size: $font-size-micro;
        text-transform: uppercase;
        letter-spacing: $font-spacing--medium;  
    }

    .form-check,
    .form-check + .link {
        margin-top: 1.25rem;
    }
    
    .form-check-label {
        padding-top: 0.125rem;
    }
    
    .btn__backto-register {
        display: none;
    }

    .button--primary {
        min-height: 0;
        height: 1.875rem;
        margin-bottom: 1.375rem;
        letter-spacing: 0.06rem;
    }

    .form-actions {
        margin: 0;

        &.btn--dropdown__register {
            display: flex;
            font-weight: $font-weight-semibold;
            margin-top: 1.225rem;
            letter-spacing: 0.0225rem;
        }

        &:not(.btn--dropdown__register) {
            &::after {
                @include relative(left -1.375rem);
                content: " ";
                display: block;
                border-bottom: solid 0.0313rem $color-grey-4;
                width: 150%;
            }
        }

        .button {
            padding: 0.3125rem 0;
        }
    }

    .form-actions__header {
        max-width: 11.875rem;
        margin-bottom: 1.25rem;
    }

    .btn--dropdown__register {
        font-weight: $font-weight-semibold;
        margin-top: 1.225rem;
        letter-spacing: 0.0225rem;
    }
}

@include media-query(medium-up) {
    .dropdown-menu {
        &.dropdown--account {
            &.toggle--active {
                width: 14.4rem;
            }
        }
    }
}

@include media-query(medium) {
    .header-flyout__secondary {
        .login-dropdown {
            &.logged-in {
                display: block;
                letter-spacing: rem(2.16);
                padding-top: rem(24);
                .logged-in-label {
                    height: auto;
                    font-weight: $font-weight-normal;
                    display: inline-flex;
                    padding: 0 1.25rem 0.5rem;
                    .icon__profile {
                        width: 0.9rem;
                        height: 0.9rem;
                    }
                }
                .log-out-label {
                    padding-right: 1.25rem;
                    margin-bottom: 0;
                    float: right;
                }
                .dropdown--account {
                    .dropdown--account__content {
                        padding: 1rem 5rem 0.2rem 3rem;
                    }
                }
            }
        }
        .header__utility-item {
            letter-spacing: rem(2.16);
        }
        .country-dropdown {
            padding: 0.625rem 1.25rem 7.75rem 1.25rem;
            .icon--set-right {
                margin-left: 0;
            }
        }
    }
}
.login-dropdown {
    .login-form {
        p.invalid-feedback  {
            &.set--visible {
                position: absolute;
                top: 0;
                @include media-query(medium-up) {
                    top: rem(40);
                }
            }
        }
    }
}