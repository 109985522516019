@import "~app_accelerator_core/components/breadcrumb";

.breadcrumbs {
    margin-bottom: 1.0625rem;
}

.breadcrumbs__item {
    letter-spacing: $font-spacing--medium;
}

.search-results__main  {
    .breadcrumbs {
        margin-bottom: .5rem;

        &:not(.heading-type--h1) {
            padding-top: rem(19);

            @include media-query(small) {
                margin-left: 1rem;
            }
        }

        &.heading-type--h1 {
            padding-right: rem(19);
        }
    }
    .breadcrumbs__item {
        font-size: $font-size-micro;
    }
}