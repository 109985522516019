
.product-attribute__list--quickadd {
    .product-attribute {
        position: relative;

        &:not(:last-child) {
            margin-bottom: rem(16px);
        }
    }

        .product-attribute__head {
            display: none;
        }

    .product-attribute--type-swatch {
        .product-attribute__contents {
            margin: auto;
            max-width: 11.875rem;
            transform: scaleY(-1);
            display: none;
        }

        .product-attribute__contents--short {
            justify-content: center;
        }
    }

    .product-attribute--type-anchor {
        .product-attribute__contents {
           justify-content: center;
        }
    }

        .product-attribute__swatch {
            margin: 0 0.5rem 0.9375rem;
        }

        .product-attribute__anchor {
            margin: .25rem;
        }

        .product-attribute__size-chart {
            display: none;
        }

}

.quickadd__footer {
    .loader {
        display: none;
    }
}
