.dropdown.set--size-small {
    @include dropdown($padding: 1rem, $size: 7rem);
}

.dropdown.set--size-regular {
    @include dropdown($padding: 1.5rem, $size: 12.25rem);
}

.dropdown.set--size-medium {
    @include dropdown($padding: 1.5rem, $size: 20rem);
}
