@import "~app_accelerator_core/modules/modals";

.modal--small {
    &.size-chart {
        .window-modal__content {
            width: auto;
            .cms-body-content {
                max-width: rem(450);
            }
        }
    }
}

.modal--cart-login {
    .window-modal__content {
        max-width: rem(414);
    }

    .cartLoginForm {
        padding: rem(80) rem(30.5) rem(62);
    
        .form-group {
            flex-grow: 2;
            width: 100%
        }

        .form-control,
        .form-control-label {
            max-height: rem(35);
            font-size: $font-size-centi;
            letter-spacing: rem(.6);
        }
    
        .password-trigger {
            @include absolute(right rem(1.44) top rem(16));
    
            .password-trigger__icon {
                height: rem(13.4);
                width: rem(17.1);
            }
    
            .password-trigger__icon--hidden {
                height: rem(17.8)
            }
    
            &.invalid-feedback::after {
                right: rem(30);
            }
        }
    
        .link__forgot-password {
            letter-spacing: rem(.36);
        }
    
        .button--primary {
            min-height: 0;
            max-height: rem(30);
            padding: rem(5);
            border-radius: rem(5);
        }
    }
}

.modal__password-reset {
    .window-modal__content {
        max-width: rem(414);
        min-height: rem(540);
        padding: rem(87) rem(30) rem(62);
    }

    .modal__title {        
        font-size: $font-size-deka;
        font-weight: $font-weight-medium;
        margin-bottom: rem(28.8);
        line-height: 1.1em;
        letter-spacing: rem(.6);
        text-align: left;
    }

    .modal__description {
        font-size: $font-size-centi;
        margin-bottom: rem(36);
        text-align: left;
        max-width: rem(248);
        letter-spacing: $font-spacing--medium;
        color: $color-grey-26;
    }

    .modal__line-break {
        opacity: .6;
        border-bottom: solid rem(.5px);
        border-color: $color-grey-31;
    }

    .modal__content {
        margin-top: rem(30);
    }

    .form-control,
    .form-control-label {
        max-height: rem(35);
        font-size: $font-size-centi;
        letter-spacing: rem(.6);
    }

    .modal__actions {
        margin-top: rem(5);
        text-align: center;
        
        .button {
            font-size: rem(11.2);
            min-height: 0;
            padding: rem(5);
            max-height: rem(30);
            letter-spacing: rem(1);
            font-weight: $font-weight-medium;
            border-radius: rem(5);
        }
    }
}