.header__utility-item {
    margin-left: 1.375rem;
}

.header__utility-item--badged {
    position: relative;

    &.set--has-items {
        .header__utility-anchor--no-items {
            display: none;
        }
    }

    &:not(.set--has-items) {
        .header__utility-anchor--has-items {
            display: none;
        }
    }
}

.header__utility-anchor {
    display: flex;
    align-items: center;
}

.header__utility-label {
    font-size: $font-size-centi;
	white-space: nowrap;
}

.header__utility-anchor {
    position: relative;
    color: $header-nav-color;
}

.header__utility-badge {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 1rem;
    height: 1rem;
    padding-right: 0.25em;
    padding-left: 0.25em;
    border-radius: 1rem;
    font-size: 0.5625rem; //Intentionally not using a variable.
    font-weight: $font-weight-bold;
}

.header-wishlist-icon {
    position: relative;

    .wishlist-count {
        border-radius: 50%;
        color: white;
        background: $color-pink;
        width: .9rem;
        height: .9rem;
        position: absolute;
        font-size: rem(7);
        text-align: center;
        padding: rem(2);
        right: -7px;
        @include media-query(small-up) {
            width: 1rem;
            height: 1rem;
            font-size: rem(10);
        }
    }
}