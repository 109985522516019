
.product-line-item {
    position: relative;

    &:not(:last-child) {
        border-bottom: solid .0625rem $color-grey-3;
    }

    &.set--in-wishlist {
        .product-line-item__wishlist-add {
            display: none;
        }

        .product-line-item__wishlist-remove {
            display: inline-block;
        }
    }
}

.product-line-item--wishlist {
    &.set--removed-wishlist {
        .product-line-item__wishlist-revert {
            display: block;
        }

        .product-line-item__main {
            display: none;
        }
    }
}

.product-line-item--order {
    overflow: hidden;
}

.product-line-item--shipment {
    margin-bottom: 1.25rem;
    padding-bottom: 1.5rem;
    border-bottom: dashed .0625rem $color-grey-2;
}

    .product-line-item__media-link {
        display: block;
    }

    .product-line-item__header {
        margin-bottom: rem(12);
    }

    .product-line-item__footer {
        margin-top: 1.25rem;
    }

    .product-line-item__attribute,
    .product-line-item__action {
        &:not(:last-child) {
            margin-bottom: .25em;
        }
    }

    .product-line-item__actions {
        margin-top: 1.25rem;
        white-space: nowrap;
    }

    .product-line-item__wishlist-remove {
        display: none;
    }

    .product-line-item__wishlist-revert {
        display: none;
    }

    .product-line-item__pickup {
        margin-top: 1rem;
    }

/*------------------------------------*\
  #BUNDLE PRODUCTS
\*------------------------------------*/

.bundled-list {
    position: relative;
    margin-top: 1.25rem;
    border-top: dashed .0625rem $color-grey-2;
}

    .bundled-list__title {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 10rem;
        margin: auto;
        transform: translateY(-55%);
    }

    .bundled-list-item {
        margin-top: 1.25rem;
    }

        .bundled-list-item__media {
            margin-right: .25rem;
        }


@include media-query(medium-up) {
    .product-line-item--order,
    .product-line-item--minicart,
    .product-line-item--wishlist {
        .product-line-item__qty-pricing {
            margin-top: 1.25rem;
        }
    }
}

@include media-query(medium) {
    .product-line-item__qty-pricing {
        margin-top: 1rem;
    }
}

@include media-query(small-up) {
    .product-line-item {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .product-line-item--minicart {
        padding-right: 1.25rem;
        padding-left: 1.25rem;
    }

        .product-line-item__actions {
            display: flex;
        }

            .product-line-item__action {
                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }
}

@include media-query(small) {
    .product-line-item {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
    }

    .product-line-item--minicart {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}
