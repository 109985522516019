.category-tab-container {
    //Prevent product tile components from clipping.
    .slick-list {
        padding-left: .5rem;
        padding-right: .5rem;
    }
}

    .category-tab__list {
        margin: 1.25rem auto;
    }

        .category-tab__list-item {
            &:not(:last-child) {
                border-right: .0625rem solid currentColor;
            }
        }

        .category-tab__list-anchor {
            letter-spacing: .0625em;
            padding-left: 1.25rem;
            padding-right: 1.25rem;

            .set--tab-selected & {
                color: $color-primary;
                text-decoration: underline;
                -webkit-text-stroke-width: .1rem;
            }
        }

    .category-tab__content-container:not(.slick-initialized) {
        .category-tab__content:not(:first-child) {
            display: none;
        }
    }

@include media-query(small) {
    .category-tab__content-container {
        //Account for added horizontal padding if the container has the outer gutter option enabled.
        //This is needed to prevent a right gap on mobile, since the product tiles use free scrolling.
        .module-container--has-outer-gutter & {
            margin-right: -1rem;
        }
    }
}

