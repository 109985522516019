
.quickview__main {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
}

    .quickview__attribute-list,
    .quickview__availability {
        margin-top: 1.5rem;
    }

    .quickview__availability {
        margin-bottom: 1.5rem;
    }

        .quickview__promotions {
            margin-bottom: .75rem;
        }

.quickview__bundle-items {
    padding: 1.875rem;
}

    .quickview__section--actions {
        margin-bottom: 1.25rem;
    }

        .quickview__product-anchor {
            padding: .5rem 0;
        }

@include media-query(small-up) {
    .quickview__footer {
        position: relative;
        margin-top: auto;
    }
}

@include media-query(small) {
    .quickview__footer {
        margin-top: 2rem;
    }
}
