
.header-flyout__container {
    &.level-1 {
        color: $header-nav-color;
    }

    &.level-2 {
        color: $header-nav-panel-color;
    }
}

.header-flyout__close {
    color: $header-nav-panel-color;
}

@include media-query(medium-up-to-xmedium) {
    .header-flyout__list {
        .header-flyout__anchor {
            &.level-1 {
                padding: 1.25rem 0.5rem;
				font-size: $font-size-h6;
            }
        }
    }
}

@include media-query(medium-up) {
    .header-flyout {
        &:hover,
        &.mega--active {
            .header-flyout__anchor.level-1:not(.mega-item--active):not(:hover) {
                opacity: .6;
            }
        }
    }

    .header-flyout__list {
        &.level-1 {
            display: flex;
        }

        &.level-2 {
            display: flex;
            align-content: flex-start;
            justify-content: flex-start;
            padding: 1.5rem 0 1.5rem;
            flex-direction: row;
        }

        &.level-3 {
            margin-top: .75rem;
        }
    }

    .header-flyout__item {
        &.header-flyout__item--main {
            display: none;
        }
        &.level-2 {
            margin-bottom: 1.25rem;
            font-size: $font-size-h5;
            max-width: rem(340);
            flex-basis: 25%;
            flex-grow: 1;

            .layout-1 {
                .menu-horizontal {
                    padding-left: 0;
                    .sub-menu-list {
                        padding: 0.5rem 0;
                        line-height: 1.2;
                        letter-spacing: rem(1);
                    }
                }
            }
        }

        &.level-3 {
            font-size: $font-size-deci;

            &:not(:last-child) {
                margin-bottom: .5rem;
            }
        }
    }

    .header-flyout__item--main {
        &.level-2 {

        }
    }

    .header-flyout__anchor {
        &.level-1 {
            display: block;
            padding: 1.25rem 1.1rem;
            font-size: $font-size-deci;
            font-weight: $font-weight-medium;
            transition-property: opacity, box-shadow;
            transition-duration: $transition-speed-fast;
            transition-timing-function: $transition-ease-default;
            letter-spacing: 1px;
        }

        &:not(.level-1) {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .header-flyout__anchor--parent,
    .header-flyout__anchor--list-title {
        &.level-2 {
            font-weight: $font-weight-bold;
        }

        &.level-3 {
            text-decoration: underline;
        }
    }

    .header-flyout__container {
        &.level-2 {
            z-index: 1000;
            position: absolute;
            left: 0;
            background-color: $header-nav-panel-bg;
            width: 100%;
            padding: 0 2.5rem;
            overflow: auto;
            max-height: 40rem;

            &:not(.mega-item--active) {
                @include set-invisible();
            }
        }
    }

    .header-flyout__column-container {
        display: flex;

        .header-flyout__tile-img {
            height: 16rem;
            max-width: none;
        }
    }

    //These class names are added through Page Designer:
    .header-flyout__column--mainNavigationColumn {
        flex: 0 1 12.5rem;
        min-width: 8rem;

        // &:first-child:not(.header-flyout__column--align-right) {
        //     margin-left: $gutter-outer-deci;
        // }
    }

    .header-flyout__column--mainNavigationMediaColumn {
        max-width: 100%;
        overflow: auto;
    }

    .header-flyout__column--align-right {
        margin-left: auto;
    }
    //End Page Designer class names

    .header-flyout__column,
    .header-flyout__column-media {
        &:not(:last-child) {
            margin-right: $gutter-outer-deci;
        }
    }

    .header-flyout__column-media-container {
        display: flex;
        // overflow: auto;
    }

    .header-flyout__stacked-container {
        &:not(:first-child) {
            margin-top: $gutter-outer-deci;
        }
    }
}

@include media-query(medium) {
    .header-flyout {
        z-index: 1;
        display: flex;
        flex-direction: column;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $header-nav-panel-bg;
        overflow: hidden;
        transition-property: transform;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-out;

        &:not(.flyout-toggle--active) {
            transform: translateX(-100%);
            @include set-invisible();
        }

        &.flyout-toggle--active {
            transform: translateX(0);
            @include set-visible();

            .header-flyout__anchor.level-1,
            .header-flyout__secondary-link {
                transform: translateX(0);
            }
        }
    }

    .header-flyout__head {
        position: relative;
        top: 0.3125rem;
        left: 0;
        width: 100%;
    }

    .header-flyout__close {
        z-index: 2;
        margin-left: auto;
        padding: 1rem 1.25rem 0;
    }

    .header-flyout__back {
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
        background-color: $header-nav-panel-bg;
    }

    .header-flyout__container {
        &.level-1 {
            position: relative;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            max-height: 100%;
        }

        &:not(.level-1) {
            z-index: 1;
            width: 100%;
            height: 100%;
            background-color: $header-nav-panel-bg;
            transition-property: transform;
            transition-duration: $transition-speed-fast;
            transition-timing-function: $transition-ease-in;

            &.mega-item--active {
                transform: translateX(0);
                transition-timing-function: $transition-ease-out;
                //@include set-visible();
                display: block;
            }

            &:not(.mega-item--active) {
                display: none;
            }
        }
        &.level-2 {
            padding-left: 1.25rem;


            .header-flyout__anchor.mega-item--active {
                font-weight: $font-weight-bold;
                text-decoration: underline;

                &:has(+ .level-3 .mega-item--active) {
                    font-weight: $font-weight-bold;
                    text-decoration: underline;
                }
            }

            .header-flyout__scrollable {
                padding-top: 0;
                margin-top: 0;
                .header-flyout__item {
                    .header-flyout__anchor {
                        padding: rem(7) 0;
                        font-size: $font-size-h5;
                    }
                }
            }
        }
        &.level-3 {
            .header-flyout__anchor {
                .badge {
                    margin-left: rem(5);
                }
            }
        }

        &.level-4 {
            padding-left: 2.45rem;
        }
    }

    .header-flyout__scrollable {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        margin-top: 8.75rem;
    }

    .header-flyout__anchor,
    .header-flyout__back,
    .header-flyout__secondary-link {
        display: flex;
        padding: 1rem 1.25rem;
        align-items: center;
    }

    .header-flyout__anchor.level-1,
    .header-flyout__secondary-link {
        transform: translateX(-5rem);

        transition-property: transform;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-out;
        transition-delay: $transition-speed-faster;
    }

    .header-flyout__item {
        &.level-1 {
            border-bottom: 1px solid $color-grey-2;
            &:first-child {
                border-top: 1px solid $color-grey-2;
            }
            .icon {
                transition: transform .4s;
            }
            &.mega-item--active {
                .icon {
                    transform: rotate(180deg);
                }
            }
            .header-flyout__list {
                &.level-2 {
                    .header-flyout__item {
                        &.level-2 {
                            &:last-child {
                                padding-bottom: rem(15);
                            }
                        }
                        &.sub-menu {
                            font-size: $font-size-h5;
                            .sub-menu-list {
                                padding: rem(7) 0;                                
                            }
                        }
                    }
                }
            }
        }
    }

    .header-flyout__anchor {
        &.level-1 {
            letter-spacing: rem(.7);
            font-size: $font-size-h5;
            font-weight: $font-weight-medium;
        }
    }

    .header-flyout__anchor--parent {
        font-weight: $font-weight-bold;
    }

    .header-flyout__tile-img {
        width: 100%;
    }

    .header-flyout__secondary {
        margin-top: .5rem;
        padding-top: .5rem;
        border-top: solid .0625rem $color-grey-2;
        display: flex;
        flex-direction: column;

        //Mobile nav order
        .login-dropdown {
            order: 1;
        }
        .nav--track-order {
            order: 2;
        }
        .nav--rewards {
            order: 3;
        }
        .nav--accessibility {
            order: 4;
            cursor: pointer;
        }
        .cs-dropdown {
            order: 5;
        }
        .country-dropdown {
            order: 10;
        }
    }

    .header-flyout__secondary .header__utility-item {
        background-color: $color-grey-24;
        margin-left: 0;
        width: 100%;

        &.login-dropdown {
            padding-top: rem(26);
        }
    }

    .header-flyout__secondary .header__utility-label {
        font-size: $font-size-h5;
    }

    .header-flyout__secondary .dropdown[class*="set--size-"] .dropdown-menu {
        position: static;
        transform: translateX(0);
        border: 0;
        width: 100%;

        @include media-query(small) {
            padding-inline: rem(6);

            .dropdown-item {
                letter-spacing: rem(2.16);
            }

            .icon {
                margin-right: rem(8);
            }
        }
    }

    .header-flyout__secondary-link {
        padding: .5rem 1.25rem;
        color: $color-grey-5;
    }
}

@include media-query(small) {
	.header-flyout__scrollable {
		margin-top: 4.25rem;
	}
}

@include media-query(small-up-to-large) {
	.header-flyout__anchor {
		&.level-1 {
			&:lang(fr) {
				display: block;
				padding: 1.25rem 0.7em;
				font-size: 0.62rem;
				font-weight: $font-weight-medium;
				transition-property: opacity, box-shadow;
				transition-duration: $transition-speed-fast;
				transition-timing-function: $transition-ease-default;
				letter-spacing: 1px;
			}
		}
	
		&:not(.level-1) {
			&:hover {
				text-decoration: underline;
			}
		}
	}	
}
