@import "~app_accelerator_core/helpers/mixins";

/**
 * Custom font definition
 *
 * Example of utilization
 * @include line-clamp(1);
 */

@mixin line-clamp($lines: 2) {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines;
}

/**
 * Custom font definition
 *
 * Example of utilization
 * @include font-face('Graphik', 'Graphik-Medium', 'graphik/medium', 500);
 */

@mixin font-face($font-name, $file-name, $folder-name, $font-weight: normal, $font-style: normal) {
    @font-face {
        font-stretch: condensed;
        unicode-range: U+0020-00FE;
        font-display: swap;
        font-weight: $font-weight;
        font-style: $font-style;
        font-family: quote($font-name);
        src: url($font-path + $file-name + '.eot');
        src:
            url($font-path + $folder-name + '/' + $file-name + '.eot?#iefix')  format('embedded-opentype'),
            url($font-path + $folder-name + '/' + $file-name + '.woff') format('woff'),
            url($font-path + $folder-name + '/' + $file-name + '.woff2') format('woff2'),
            url($font-path + $folder-name + '/' + $file-name + '.ttf') format('truetype'),
            url($font-path + $folder-name + '/' + $file-name) format('svg');
    }
}

/**
 * Position definition
 *
 * Example of utilization
 * @include absolute(top 1rem left 2rem);
 */

@mixin position($position, $args: ()) {
    $offsets: top right bottom left;

    position: $position;

    @each $offset in $offsets {
        $index: index($args, $offset);

        @if $index {
            @if $index == length($args) {
                #{$offset}: 0;
            }

            @else {
                $next: nth($args, $index + 1);

                @if is-valid-value($next) {
                    #{$offset}: $next;
                }

                @else if index($offsets, $next) {
                    #{$offset}: 0;
                }

                @else {
                    @warn 'Invalid value #{$next} for offset #{$offset}';
                }
            }
        }
    }
}

@mixin absolute($args: ()) {
    @include position(absolute, $args);
}

@mixin fixed($args: ()) {
    @include position(fixed, $args);
}

@mixin relative($args: ()) {
    @include position(relative, $args);
}

@mixin sticky($args: ()) {
    @include position(sticky, $args);
}

/**
 * Dropdown
 *
 * Example of utilization
 * @include dropdown;
 */

@mixin dropdown($padding: 1.25rem, $size: 20rem) {
    $dropdown-offset: 1rem;

    @include relative;

    .dropdown-menu {
        @include absolute(top calc(100% + .25rem) left 50%);

        background: $color-white;
        border: 1px solid $color-grey-3;
        transform: translateX(-50%);
        padding: $dropdown-offset $padding;
        width: $size;
        z-index: 3;

        &:not(.toggle--active) {
            display: none;
        }
    }

    .dropdown-toggle {
        cursor: pointer;

        &.toggle--active .icon:not(.dropdown-flag) {
            transform: rotate(180deg);
        }

        .dropdown-flag {
            height: .875rem;
            width: .875rem;
        }
    }

    .dropdown-arrow {
        margin-left: .4375rem;
    }

    .dropdown-menu__header {
        margin: 0 -1*$padding $dropdown-offset;
        padding: 0 $dropdown-offset $dropdown-offset $dropdown-offset;
        border-bottom: 1px solid $color-grey-3;
    }

    .dropdown-menu__link {
        &:not(:first-child) {
            margin-top: .5rem;
        }
    }

    .dropdown--dob {
        max-width: 3.3125rem;
        max-height: 6.5313rem;
        overflow: scroll;
        scrollbar-width: thin;
        scrollbar-color: $color-grey-10;
        color: $color-grey-10;
        padding: 0.4rem 0.2813rem 0.3875rem 0.7688rem;
        line-height: 1.25rem;
        letter-spacing: $font-spacing--medium;

        .form__dob--option:hover {
            cursor: pointer;
            color: $color-primary;
        }
    }
}

/**
 * Function to covert PX to REM
 * Example of utilization
 * font-size: rem(14px);
 */


$html-font-size: 16px;
 
@function stripUnit($value) {
    @return $value / ($value * 0 + 1);
}
@function rem($pxValue) {
    @return #{stripUnit($pxValue) / stripUnit($html-font-size)}rem;
}

@mixin custom-scollbar {
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background: transparent; 
	}
	
	&::-webkit-scrollbar-thumb {
		background: transparent; 
		border-radius: 4px;
	}
	
	&::-webkit-scrollbar-thumb:hover {
		background: $color-grey-5; 
	}
	&:hover {
		&::-webkit-scrollbar-thumb {
			background: $color-grey-32; 
		}
	}
}