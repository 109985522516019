/*------------------------------------*\
  #TEXT SIZE
\*------------------------------------*/

    .heading-type--yotta {
        font-size: $font-size-yotta;
    }

    .heading-type--zetta {
        font-size: $font-size-zetta;
    }

    .heading-type--exa {
        font-size: $font-size-exa;
    }

    .heading-type--h1 {
        font-size: $font-size-h1;
    }

    .heading-type--h2 {
        font-size: $font-size-h2;
    }

    .heading-type--h3 {
        font-size: $font-size-h3;
    }

    .heading-type--h4 {
        font-size: $font-size-h4;
    }

    .heading-type--h5 {
        font-size: $font-size-h5;
    }

    .heading-type--h6 {
        font-size: $font-size-h6;
    }


    .body-type {
        font-size: $font-size-base;
    }

    .body-type--kilo {
        font-size: $font-size-kilo;
    }

    .body-type--hecto {
        font-size: $font-size-hecto;
    }

    .body-type--deka {
        font-size: $font-size-deka;
    }

    .body-type--deci {
        font-size: $font-size-deci;
    }

    .body-type--centi {
        font-size: $font-size-centi;
    }

    .body-type--micro {
        font-size: $font-size-micro;
    }

[class*="fluid-type"] {
    line-height: normal;
}

    .fluid-type--kilo-h5 {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-kilo, $font-size-h5);
    }

    .fluid-type--deka-h5 {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-deka, $font-size-h5);
    }

    .fluid-type--hecto-h6 {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-hecto, $font-size-h6);
    }

    .fluid-type--deka-kilo {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-deka, $font-size-kilo);
    }

    .fluid-type--deka-hecto {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-deka, $font-size-hecto);
    }

    .fluid-type--base-deka {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-base, $font-size-deka);
    }

    .fluid-type--deci-base {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-deci, $font-size-base);
    }

    .fluid-type--centi-deci {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-centi, $font-size-deci);
    }

/*------------------------------------*\
  #TEXT FAMILIES
\*------------------------------------*/

.font-family--sans {
    font-family: $font-family-sans;
}

[class*="heading-type"],
.font-family--sans-secondary {
    font-family: $font-family-sans-secondary;
}

.font-family--serif {
    font-family: $font-family-serif;
}

.font-family--cursive {
    font-family: $font-family-cursive;
}


/*------------------------------------*\
  #CMS GENERIC COPY
\*------------------------------------*/
//These are default styles for containers with the class "cms-generic-copy".
//These styles provide an easy way for the business user to create pages or blocks
//of content without needing to provide specific classes or structure on the elements.
//This is useful for non-designed pages and sections.

.cms-generic-copy {
    line-height: $font-line-height-hecto;

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 1em;
        line-height: $font-line-height-milli;
        text-transform: uppercase;

        &:not(:first-child) {
            margin-top: 1em;
        }
    }

    a {
        text-decoration: underline;
        font-weight: $font-weight-semibold;

        &:hover, &:focus {
            opacity: .7;
        }
    }

    ul,
    ol,
    p + p {
        margin-top: .5em;
    }

    ol,
    ul {
        margin-bottom: .5em;

        li:not(:last-child) {
            margin-bottom: .25em;
        }
    }

    table {
        width: 100%;
        margin-bottom: 1rem;
        margin-top: 1rem;
        border: solid .0625rem;
        border-collapse: collapse;
    }

        th, td {
            border: solid .0625rem;
            padding: 0.5rem;
        }

        th {
            background-color: $color-grey-2;
        }
}

/*------------------------------------*\
  #TEXT LINE HEIGHT
\*------------------------------------*/

.text-line--collapse {
    line-height: 0;
}

.text-line--reset {
    line-height: 1;
}

.text-line--reduce {
    line-height: $font-line-height-milli;
}

.text-line--small {
    line-height: $font-line-height-deci;
}

.text-line--normal {
    line-height: $font-line-height-base;
}

.text-line--medium {
    line-height: $font-line-height-deka;
}

.text-line--large {
    line-height: $font-line-height-hecto;
}

.text-line--xlarge {
    line-height: $font-line-height-kilo;
}

.text-line--xxlarge {
    line-height: $font-line-height-mega;
}

/*------------------------------------*\
  #TEXT ALIGNMENT
\*------------------------------------*/

.text-align--left {
    text-align: left;
}

.text-align--center {
    text-align: center;
}

.text-align--right {
    text-align: right;
}

.text-align--justify {
    text-align: justify;
}

/*------------------------------------*\
  #TEXT STYLING
\*------------------------------------*/

.font-weight--light {
    font-weight: $font-weight-light;
}

.font-weight--normal {
    font-weight: $font-weight-normal;
}

.font-weight--semibold {
    font-weight: $font-weight-semibold;
}

.font-weight--bold {
    font-weight: $font-weight-bold;
}

.font-weight--extrabold {
    font-weight: $font-weight-extrabold;
}

.font-style--italic {
    font-style: italic;
}

.text-decoration--underline {
    text-decoration: underline;
}

.text-decoration--strike {
    text-decoration: line-through;
}

.text-transform--uppercase {
    text-transform: uppercase;
}

.text-transform--lowercase {
    text-transform: lowercase;
}

.text-transform--capitalize {
    text-transform: capitalize;
}

.text-transform--initial {
    text-transform: initial;
}

.white-space--nowrap {
    white-space: nowrap;
}

.text--stroke {
    display: inline-block; //Fixes Safari text wrapping issue;

    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: inherit;
    -webkit-text-stroke-width: .0625rem;
}


/*------------------------------------*\
  #TEXT SPACING
\*------------------------------------*/

//Forces each word in an element to break to the next line.
.word-spacing--large {
    word-spacing: 100vw;
}

.word-break--break-word {
    word-break: break-word;
}

.hyphens--auto {
    hyphens: auto;
}

/*------------------------------------*\
  #TEXT COLOR
\*------------------------------------*/

.text-color {
    @include property-modifiers($colors);
}

.text-color--initial {
    color: initial;
}

@include media-query(small-up) {
    /*------------------------------------*\
      #TEXT ALIGNMENT
    \*------------------------------------*/

    .text-align--small-up-center {
        text-align: center;
    }

}

@include media-query(medium) {
    /*------------------------------------*\
      #TEXT ALIGNMENT
    \*------------------------------------*/

    .text-align--medium-center {
        text-align: center;
    }

}

@include media-query(small) {
    /*------------------------------------*\
      #TEXT ALIGNMENT
    \*------------------------------------*/

    .text-align--small-left {
        text-align: left;
    }

    .text-align--small-center {
        text-align: center;
    }

    .text-align--small-right {
        text-align: right;
    }

    .text-align--small-justify {
        text-align: justify;
    }

}
