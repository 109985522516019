.header-flyout__secondary {
    margin-top: 0;
    padding-top: 0;

    .dropdown-trigger {
        padding-top: 1.875rem;
        padding-bottom: 1.6875rem;
        padding-left: 1.9375rem;
        padding-right: 1.4375rem;
        font-size: $font-size-deci;
        background-color: $color-grey-20;
        line-height: 2.5rem;
        letter-spacing: 0.0262rem;

        .icon__profile {
            fill: none;
            stroke: $color-black;
            @include relative(top 0.25rem);
            font-size: 1.1875rem;
            margin-right: 0.25rem;
        }
    }
    
    .dropdown {
        .icon {
            transition: transform .4s;
            @include media-query(medium) {
                width: 0.9rem;
                height: 0.9rem;
            }
        }

        .toggle--active {
            .dropdown-arrow .icon {
                transform: rotate(180deg);
            }
        }

        .dropdown-content {
            padding-inline: 1.2813rem;
            padding-top: 1.9375rem;
        }
        
        .form-group-container {
            flex-direction: column;
        }
    }

    .form-group.required .form-control {
        font-size: $font-size-deci;
        letter-spacing: 0.0262rem;
    }

    .password-trigger {
        @include relative(bottom 1.25rem left calc(100% - 0.875rem));
        
        .password-trigger__icon {
            width: 1.175rem;
            height: 0.875rem;
        }
    }

    .form-check-label,
    .form-check + .link,
    .link--underline:not(.body-type--centi) {
        font-size: $font-size-deci;
    }

    .form-actions__header {
        display: none;
    }

    .btn--dropdown__register {
        margin-bottom: 3.1875rem;
    }

    .form-actions:not(.btn--dropdown__register)::after {
        display: none;
    }

    @include media-query(medium) {
        .login-dropdown .dropdown-toggle {
            &.login-label {
                .icon__profile {
                    position: absolute;
                    width: 1rem;
                    height: 1rem;
                    top: 2.15rem;
                }
                .header__utility-label {
                    padding-left: 1.5rem;
                }
                &.toggle--active {
                    .icon__profile {
                        transform: rotate(0);
                    }
                }
            }
        }
    }

    @include media-query(medium) {
        .login-dropdown .dropdown-toggle {
            padding: 0.5rem 1.25rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: $font-weight-normal;
        }

        .dropdown .dropdown-menu.dropdown--login.toggle--active {
            padding: 2.1875rem 1.25rem 0;
        }
        .header-flyout__secondary-link {
            .header__utility-label {
                padding-left: 0.5rem;
            }
            .icon__profile {
                margin: 0;
                width: 1rem;
                height: 1rem;
            }
        }
        
        h2.dropdown-login__subtitle {
            display: none;
        }

        .dropdown--account__rewards {
            border: 0;
            padding-top: 2.5rem;
        }

        .dropdown--account__header, .dropdown--account__nav-item {
            font-size: $font-size-h5;
            margin-bottom: 0.9375rem;
        }

        .help-subscription,
        .help-giftcard,
        .help-label,
        .help-country {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .icon__profile {
                position: absolute;
                width: 1rem;
                height: 1rem;
                top: 0.5rem;
            }
            .header__utility-label {
                padding-left: 1.5rem;
            }
            &.toggle--active {
                .icon__profile {
                    transform: rotate(0);
                }
            }
        }

        .help-subscription,
        .help-giftcard {
            .icon__profile {
                fill: unset;
            }
        }

        .dropdown.toggle--active {
            .dropdown-toggle.toggle--active {
                .icon__profile {
                    transform: none;
                }
            }
        }
    }
    
    @include media-query(small) {
        .dropdown .dropdown-menu.dropdown--login.toggle--active {
            padding: 3rem 1.25rem 0;
        }
    }
}

