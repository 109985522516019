.footer__social {
    .content-asset {
        display: flex;
        justify-content: space-between;
    }
}

    .footer__social-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .icon {
            height: 2.75rem;
            width: 2.75rem;
        }

        .icon--hover {
            display: none;
        }
    }

    .footer__social-icon:hover,
    .footer__applications-icon:hover {
        .icon {
            display: none;
        }

        .icon--hover {
            display: block;
        }
    }

    .footer__payments-icon {
        display: inline-block;
        vertical-align: middle;
        justify-content: center;
        margin-right: .3125rem;

        .icon {
            height: 1rem;
            width: 1.75rem;
        }
    }

        .footer__payments-icon--lock {
            .icon {
                height: 1rem;
                width: .75rem;
            }
        }

        .footer__payments-icon--applepay,
        .footer__payments-icon--googlepay {
            .icon {
                min-height: 1.75rem;
            }
        }
    .footer__applications-icons {
        display: flex;
        justify-content: space-between;
        margin-top: 1.25rem;
    }

        .footer__applications-link {
            flex: 0 0 48.5%;
            width: 48.5%;
        }


        .footer__applications-link:hover {
            .footer__applications-icon {
                display: none;
            }

            .footer__applications-icon--hover {
                display: block;
            }
        }

            .footer__applications-icon--hover {
                display: none;
            }

@include media-query(small-up-to-medium) {
    .footer__applications-icons,
    .footer__social .content-asset {
        max-width: 50%;
    }

    .footer__payments-icon {
        margin-right: .625rem;

        .icon {
            height: 2.1875rem;
            width: 2.1875rem;
        }
    }

    .footer__payments-icon--lock {
        .icon {
            height: 1.4375rem;
            width: 1.0625rem;
        }
    }
}

@include media-query(medium-up) {
    .footer__payments-icon {
        margin-right: .1875rem;

        .icon {
            height: 1.875rem;
            width: 1.875rem;
        }
    }

    .footer__payments-icon--lock {
        .icon {
            height: 1.4375rem;
            width: 1.0625rem;
        }
    }

        .footer__social-icon {
            .icon {
                height: 2.1875rem;
                width: 2.1875rem;
            }
        }

    .footer__applications {
        margin-top: 3rem;
    }

        .footer__applications-icon {
            height: 1.9375rem;
        }
}


@include media-query(xmedium-up) {
    .footer__payments-icon:not(.footer__payments-icon--lock) {
        .icon {
            height: 2.1875rem;
            width: 2.1875rem;
        }
    }
}

@include media-query(large-up) {
    .footer__payments-icon {
        margin-right: .625rem;
    }
}
