    // Social Shop Content Asset Style for Instagram & TikTok

    .int-page-header {
        display: flex;
        justify-content: center;
        margin: 3.4em 0;
    }

    .int-page-header h1 {
        text-align: center;
        font-weight: $font-weight-semibold;
        font-size: 2.5rem;
        text-transform: initial;
    }

    .int-page-header svg {
        width: 2.375rem;
        margin-right: 1.25rem;
            margin-top: -0.1875rem;
    }

    @media (max-width: 768px) {
        .int-page-header {
            margin: 2.4em 0;
        }

        .int-page-header h1 {

            font-size: 1.7rem;
            text-transform: initial;
        }

        .int-page-header svg {
            width: 1.75rem;
            margin-right: 0.9375rem;
        }
    }


