.footer,
.footer__secondary-content {
    border-top: 1px solid $color-grey-4;
    padding-top: 0;
    margin-top: 1.875rem;
    background: $color-white;
}

    .footer__copyright {
        text-align: center;
        font-size: $font-size-centi;
        color: $color-grey-4;
        pointer-events: none;
    }

    .set--margin-0 {
        margin: 0;
    }

@include media-query(medium) {
    .footer__secondary-content {
        padding-top: 2.25rem;
        padding-bottom: 2rem;
    }

    .footer__main-content {
        .subscription-banner,
        .footer__applications {
            padding-top: 3.125rem;
        }

        .subscription-banner--message {
            padding-top: 2.5rem;
        }
    }

    .footer__container {
        flex-direction: column;
    }

    .footer__copyright {
        padding-top: 2.25rem;
        order: 1;
    }
}

@include media-query(medium-up) {
    .footer {
        position: relative;
        padding-top: 1.875em;

        &.set--sticky,
        &.set--visible,
        &.set--regular {
            position: fixed;
            width: 100%;
            bottom: 0;
            left: 0;
            right: 0;
            transform: translateY(calc(100% - 2.1875rem));
            z-index: 999;
        }

        &.set--sticky {
            padding-top: .5rem;
        }

        &.set--visible,
        &.set--regular {
            transform: translateY(0);
        }

        &.set--regular {
            @include absolute;
        }
    }

    .footer__secondary-content {
        padding: .5rem 0;
    }

    .footer__main-content {
        display: flex;
        justify-content: space-between;
    }

        .footer__info-group {
            min-width: 20%;
            max-width: 20%;
        }

    .footer__container {
        position: relative;
        align-items: center;
    }

    .footer__copyright {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
    }

        .footer__applications-title {
            letter-spacing: $font-spacing--large;
        }

    .footer__arrow {
        position: absolute;
        right: 1.25rem;
        top: .6rem;
        transition: transform .25s;

        .footer.set--visible & {
            top: 1.5rem;
            transform: rotate(180deg);
        }

        .footer.set--regular & {
            display: none;
        }
    }
}

@include media-query(xmedium-up) {
    .footer__container,
    .footer__main-content {
        padding: 0 5.5rem;
    }

    .footer__info-group {
        min-width: 9.5rem;
    }

    .footer__info-group--large,
    .footer__copyright-additional {
        min-width: 12.25rem;
    }
}

@include media-query(large-up) {
    .footer__container,
    .footer__main-content {
        padding: 0 9.5rem;
    }

    .footer__info-group {
        min-width: 13.5rem;
    }

    .footer__info-group--large,
    .footer__copyright-additional {
        min-width: 16.25rem;
    }
}
