
.hero,
.hero__aspect-ratio {
    //Ensure relative position on viewports that might not use `aspect-ratio`.
    position: relative;
}

.hero--size-large {
    .hero__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h3, $font-size-h1);
    }
}

.hero--size-regular {
    .hero__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h4, $font-size-h2);
    }
}

.hero--size-small {
    .hero__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-h6, $font-size-h4);
    }
}

.hero--size-tiny {
    .hero__title {
        @include fluid-type(map-get($breakpoints, small), map-get($breakpoints, large), $font-size-hecto, $font-size-h5);
    }
}

    .hero__subtitle {
        margin-bottom: .25em;
    }

    .hero__actions {
        margin-right: -.5rem;
        margin-left: -.5rem;
    }

        .hero__cta {
            display: inline-block;
            margin-right: .5rem;
            margin-left: .5rem;

            &:not(:last-child) {
                margin-bottom: 1.25rem
            }
        }

@include media-query(large-up) {
    .hero--viewport {
        .hero__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 9rem;
                padding-bottom: 9rem;
            }

            &.set--text-after,
            &.set--text-after-large {
                padding-top: 3.3rem;
            }

            &.set--text-before,
            &.set--text-before-large {
                padding-bottom: 3.3rem;
            }
        }
    }

    //Hacky way to force Slick to respect the Hero's height when in a carousel.
    //This is necessary since Slick adds height: 100%; and min-height: 1px; to slide items.
    .hero__aspect-ratio {
        &:before {
            display: block;
            content: '';
        }
    }

    .hero__aspect-ratio--main {
        &:before {
            height: 43.75rem;
        }
    }

    .hero__aspect-ratio--slim {
        &:before {
            height: 31.25rem;
        }
    }

    .hero__aspect-ratio--hairline {
        &:before {
            height: 18.75rem;
        }
    }
}

@include media-query(small-up-to-large) {
    .hero__aspect-ratio--main {
        @include aspect-ratio(1440, 700, true);
    }

    .hero__aspect-ratio--slim {
        @include aspect-ratio(1440, 500, true);
    }

    .hero__aspect-ratio--hairline {
        @include aspect-ratio(1440, 300, true);
    }

    .hero--viewport {
        .hero__content-wrap {
            &.set--text-overlay,
            &.set--text-overlay-large {
                padding-top: 10vh;
                padding-bottom: 10vh;
            }

            &.set--text-after,
            &.set--text-after-large {
                padding-top: 5.4vh;
            }

            &.set--text-before,
            &.set--text-before-large {
                padding-bottom: 5.4vh;
            }
        }
    }
}

@include media-query(small-up) {
    .hero--viewport,
    .hero--main,
    .hero--natural {
        .hero__content-wrap {
            padding-top: 3.75rem;
            padding-bottom: 3.75rem;
        }
    }

    .hero--slim {
        .hero__content-wrap {
            padding-top: 2.5rem;
            padding-bottom: 2.5rem;
        }
    }

    .hero--hairline {
        .hero__content-wrap {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
        }
    }

    .hero__aspect-ratio--viewport {
        &:before {
            display: block;
            //Substract the desired header size from the viewport height.
            height: calc(100vh - 4.125rem);
            min-height: 27.5rem;
            content: '';
        }

        //.set--header-blend Is set in the ".page" element (pdStorePage.isml, page.isml, etc.).
        //This modifier class controls whether or not the header should be blend and transparent.
        //Whenever this class is present, the "viewport height" heroes should occupy the full viewport's height.
        .set--header-blend &:before {
            height: 100vh;
        }
    }

    .hero__description {
        margin-top: .5em;
        max-width: 40rem;

        .text-align--center & {
            margin-left: auto;
            margin-right: auto;
        }

        .text-align--right & {
            margin-left: auto;
        }
    }

    .hero__actions {
        margin-top: 1.75rem;
    }
}

@include media-query(small) {
    .hero--main,
    .hero--viewport {
        .hero__actions {
            margin-top: 1.25rem;
        }
    }

    .hero--slim,
    .hero--hairline,
    .hero--natural {
        .hero__actions {
            margin-top: 1rem;
        }
    }

    .hero__aspect-ratio--viewport {
        &:before {
            display: block;
            height: calc(100vh - 11rem);
            min-height: 16.25rem;
            content: '';
        }

        //.set--header-blend Is set in the ".page" element (pdStorePage.isml, page.isml, etc.).
        //This modifier class controls whether or not the header should be blend and transparent.
        //Whenever this class is present, the "viewport height" heroes should occupy the full viewport's height (minus the iOS nav-bar).
        .set--header-blend &:before {
            height: calc(100vh - 6rem);
        }
    }

    .hero__aspect-ratio--main {
        @include aspect-ratio(375, 536, true);
    }

    .hero__aspect-ratio--slim {
        @include aspect-ratio(375, 225, true);
    }

    .hero__aspect-ratio--hairline {
        @include aspect-ratio(375, 188, true);
    }

        .hero__content-wrap {
            padding-top: 2rem;
            padding-bottom: 2rem;

            .hero-carousel &.set--text-after,
            .hero-carousel &.set--text-after-small {
                padding-bottom: 4rem;
            }
        }

        .hero__description {
            margin-top: .875em;
        }

            .hero__cta {
                min-width: calc(75% - 1rem);

                //Force full width whenever a PageDesigner CTA is rendered within a Module.
                .button {
                    width: 100%;
                }
            }
}
