@import "~app_accelerator_core/modules/moduleCommons";

.module-container--bordered {
    border: 1px solid;
    padding: rem(20);
}

.page--homepage {
    .experience-main {
        .experience-accelerator_layouts-flexibleCarousel,
        .experience-accelerator_layouts-2colLayout,
        .experience-accelerator_layouts-flexibleGrid {
            padding-bottom: rem(40);
            @include media-query(small-up) {
                padding-bottom: rem(90);
            }
        }

        // Ardene Category Carousel

        .experience-accelerator_layouts-flexibleCarousel {
            .slider--pre-layout-lg-5 {
                margin-left: rem(24);
                @include media-query(small-up) {
                    margin-left: rem(60);
                    .slick-list {
                        padding:0 10% 0 0 !important;
                    }
                }
            }

            .slider--pre-layout-3 {
                .slick-list{
                    @include media-query(small) {
                        padding:0 10% 0 0 !important;
                    }
                }
            }
        }

        // Ardene Context Card -- 3 & Ardene Context Card -- 4
        .experience-accelerator_layouts-flexibleCarousel {
            .slider--pre-layout-lg-3,
            .slider--pre-layout-lg-4 {
                margin-left: rem(24);
                margin-right: 0;
                @include media-query(small-up) {
                    margin-left: rem(60);
                    margin-right: rem(60);
                }
                &.slider--pre-layout-1 {
                    .slick-list{
                        @include media-query(small) {
                            padding:0 20% 0 0 !important;
                        }
                    }
                }
            }
        }


        .experience-accelerator_layouts-flexibleCarousel {
            .module-carousel
            .slick-arrow {
                color: white;
            }
        }

        .experience-accelerator_layouts-flexibleGrid {
            .module-grid {
                @include media-query(small) {
                    margin-left: 24px;
                    margin-right: 24px;
                }
            }
            .module-grid__item {
                padding: 0;
                .icon-tile {
                    margin-left: rem(2);
                    margin-right: rem(2);
                }

            }

            .content-tile__aspect-ratio--has-media {
                margin-left: rem(8);
                margin-right: rem(8)
            }

        }

        .experience-accelerator_layouts-flexibleGrid{
            .module-grid {
                .col-6 {
                    .icon-tile {
                        @include media-query(small) {
                            margin: 3px -1.5px;
                        }
                    }

                }
            }

        }

            .experience-accelerator_layouts-2colLayout {
                .module-grid {
                    margin-bottom: rem(30);

                    &.flex-no-gutters {
                        margin-left: -1px;
                        margin-right: 1px;

                        .module-grid__item {
                            padding: 1px;
                        }
                    }
                }

                @include media-query(small-up) {
                    .content-tile__media {
                        height: rem(500);
                    }

                    .module-grid {
                        margin-bottom: rem(30);
                    }
                }

        }

        // Ardene Category Grid
        .experience-accelerator_layouts-flexibleGrid{
            .col-4 {
                display: none;
                @include media-query(small) {
                    display: block;
                    margin-bottom: rem(12);
                    .content-cta-overlay {
                        background-image: linear-gradient(white, black);
                        opacity: 0.3;
                    }

                    .content-tile__title {
                        color: white;
                        letter-spacing: 1.2px;
                        font-size: rem(12);
                        position: relative;
                        z-index: 2;
                    }

                }
            }
        }

    }



    .component-actions__cta {
        &.button--primary {
            width: rem(250);
            height: rem(30);
            padding: rem(10);
            font-size: $font-size-base;
            @include media-query(small-up) {
                width: rem(320);
                height: rem(60);
                padding: rem(20);
                font-size: $font-size-h3;
            }
        }
    }



}

.hero-carousel {
    .slick-arrow {
        color: $color-white;

        .arrowPrevwName {
            position: absolute;
            width: 100px;
            color: $color-white;
            text-transform: uppercase;
            letter-spacing: .04rem;
            font-size: .8rem;
            width: 10rem;
            line-height: 0.96rem;
            text-shadow: 0 0 0.25rem $color-black;
            visibility: hidden;
            opacity: 0;
        }
        &.slick-prev {
            .arrowPrevwName {
                left: 3rem;
                text-align: left;
            }
        }
        &.slick-next {
            .arrowPrevwName {
                right: 3rem;
                text-align: right;
            }
        }

        &:hover {
            .arrowPrevwName {
                visibility: visible;
                opacity: 1;
            }

        }
    }
}

.trendingSliderSec  {
    margin-top: 3.75rem;

    .fluid-type--base-deka {
        font-size: $font-size-h2;
        font-weight: $font-weight-medium;
    }
    @include media-query(small-up) {
        .fluid-type--base-deka {
            font-size: 1.75rem;
        }
    }
}
.trending-carousel {
    max-width: 1440px;
    margin: 0 auto;
    @include media-query(small-up) {
        max-width: 90%; 
    }
    @include media-query(large-up) {
        max-width: 1440px;
    }
    &:hover {
        .slick-arrow {
            opacity: 1;
            visibility: visible;
        }  
    }
    .slick-arrow {
        opacity: 0;
        visibility: hidden;
        top: calc(40% + 0px);
        transform: translate3d(0, -40%, 0);
        &.slick-prev {
            left: -3.5%;
        }
    
        &.slick-next {
            right: -3.5%;
        }
    }
    
    .slick-track {
        padding-bottom: 1.5rem; 
        @include media-query(small) {
            padding-bottom: 0; 
        }
    }
    @include media-query(small-up) {
        .hero__content-wrap {
            padding-top: 1rem !important;
            padding-bottom: 0 !important;
        }
    }
    .hero__aspect-ratio {
        &.hero__aspect-ratio--main {
            height: 33.75rem;
            margin: auto 1px;
            @include media-query(small) {
                height: auto;
            }
        }
       

        .component-image {
            transition: all 0.3s;
        }

        &:hover img.component-image {
            transform: scale(1.1);
        } 
    }
    
    .hero__content {
        color: $color-black;
    }
    
    .hero__subtitle {
        font-size: $font-size-h5;
        font-weight: $font-weight-normal;
        text-transform: capitalize;

        @include media-query(small-up) {
            font-size: $font-size-h3;
        }
    }
    .hero__title.heading-type {
        position: relative;
        font-size: $font-size-h5;
        overflow: visible;
        font-weight: $font-weight-medium;
        display: inline-block;

        &:after {
            content: "";
            position: absolute;
            bottom: -3px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $color-black;
            transition: all 0.3s;
        }
        &:hover:after {
            width: 0;
        }
    }
}
