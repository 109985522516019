.product-tile__body {
    position: relative;

    &.set--quick-add-inited {
        &[data-quickadd-trigger]:hover {
            .product-promotions {
                display: none;
            }
        }
    }
}

.quick-add-trigger {
    @media screen and (min-width: 1025px) {
        display: none;
    }
}

    .product-tile__body-section {
        &:not(:first-child) {
            margin-top: .5rem;
        }
    }

    .product-tile__name {
        @include line-clamp(2);

        min-height: 1.75rem;
        overflow: hidden;
        letter-spacing: $font-spacing--medium;
    }

@include media-query(medium-up) {
    .product-tile__name {
        min-height: rem(33);
        font-size: $font-size-deci;
    }
}

@include media-query(small) {
    .product-tile__name {
        min-height: 1.5rem;
        font-size: $font-size-micro;
    }
}