/*------------------------------------*\
  #FLEX-GRID
\*------------------------------------*/

$fg-class-grid: 'flex';
$fg-use-off-half: false;

$fg-gutter: (
    xs: $gutter-inner-deci,
    lg: $gutter-inner
);

$fg-breakpoints: (
    xs: 0,
    sm: map-get($breakpoints, xsmallest),
    md: map-get($breakpoints, small) + map-get($breakpoints, unit),
    lg: map-get($breakpoints, medium) + map-get($breakpoints, unit),
    xl: map-get($breakpoints, xmedium) + map-get($breakpoints, unit)
);

@import "@borngroup/born-flexgrid/src/flex-grid";


/*------------------------------------*\
  #FLEX-HELPERS
\*------------------------------------*/
//Fixes text not wrapping properly when a child of a
//'flex' container has the 'flex-shrink: 0;' property.
//Must be applied to the 'flex' container.
.fix--overflow {
    > * {
        max-width: 100%;
    }
}

//IE Fix for col children of a parent with direction: column;
//Need to find alternative.
[class*="col-"] {
    > * {
        flex-shrink: 0;
    }

    &.flex-no-gutters {
        padding-left: 0;
        padding-right: 0;
    }
}

//Single class to make an element 'flex';
//There are more atomic classes coming from the born-flexgrid,
// so we don't have to re-declare those.
.flex {
    display: flex;
}

.flex--inline {
    display: inline-flex;
}

.flex-grow-1 {
    flex-grow: 1;
}

.flex-row {
    flex-direction: row;
}


// Order helper classes.
// Especially useful for CMS-able elements in rows.
@include generate-enumerated-class(order);

@include media-query(small-up) {
    @include generate-enumerated-class(order--small-up, order);
}

@include media-query(small) {
    @include generate-enumerated-class(order--small, order);
}
