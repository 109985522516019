.product-tile {
    .price,
    .price__promo {
        font-size: $font-size-h5;
        text-transform: uppercase;
        letter-spacing: 0.0437rem;
    }


    &.set--quickadd-active {
        pointer-events: none;
    }
}

.price__original + .price__sales,
.price__promo {
    margin-left: .375rem;
}

.price {
    .price__original + .price__sales {
        &.sales {
            font-weight: $font-weight-medium;
            color: $color-red-1;
        }
    }

    &:has(+ .price__promo) {
        .price__sales.sales {
            font-weight: $font-weight-normal;
            color: $color-primary;
            text-decoration: line-through;
        }
    }

    &.has-promo-price {
        .price__sales.sales {
            font-weight: $font-weight-normal;
            color: $color-primary;
            text-decoration: line-through;
        }
        .price__promo {
            font-weight: $font-weight-medium;
            color: $color-red-1;
        }

    }

    & + .price__promo {
        color: $color-red-1;
        font-weight: $font-weight-medium;
    }
}

@include media-query(small) {
    .product-tile {
        .price,
        .price__promo {
            font-size: .8rem;
        }
    }
}

