
.dropdown-box {
    position: relative;
}

    .dropdown-box__trigger {
        font-size: $font-size-centi;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;
        text-overflow: ellipsis;
        padding: 0.75rem 1.5rem 0.75rem 1rem;

        .dropdown-box__trigger__label {
            text-decoration: underline;
        }

        &.toggle--active {
            border: 0.5px solid #878787;
            border-bottom-width: 0px;
            
            .dropdown-box__trigger__label {
                text-decoration: none;
            }
        }
    }



    .search-results__sort-dropdown--selected {
        color: #848484;
        text-transform: capitalize;
        margin-left: 8px;
        text-decoration: none;
    }

    .dropdown-box__list {
        z-index: 2;
        position: absolute;
        top: calc(100% - .0625rem);
        right: 0;
        min-width: 100%;
        background-color: $color-white;
        border: solid .5px #878787;
        font-size: $font-size-centi;
        padding: 0 1rem 0.5rem;
        border-top: none;

        &:not(.toggle--active) {
            @include set-invisible();
        }
    }

    .dropdown-box__anchor {
        display: block;
    }


@include media-query(small) {
    .dropdown-box__list--small-expanded {
        position: relative;
        top: initial;
        border: none;

        &:not(.toggle--active) {
            @include set-visible();
        }
    }

    .dropdown-box__anchor {
        padding: 1.25rem .75rem;
    }

    .search-results__sort-dropdown--selected {
        color: $color-black;
        text-decoration: underline;
    }
}