
.header__search {

}

    .site-search__form {
        z-index: 5;
        position: absolute;
        width: 100vw;
        right: 0;
        transition-property: opacity, visibility;
        transition-duration: $transition-speed-fast;
        transition-timing-function: $transition-ease-out;

        &:not(.toggle--active) {
            @include set-invisible();
            pointer-events: none;
        }
    }

    .site-search__field {
        width: 100%;
        padding: .75rem 2rem .75rem 1rem;
        border: none;

        //In case there are transitions applied to the containing form element.
        //This allows focus to be shifted even if the form is still invisible (in transition).
        .toggle--active & {
            visibility: visible;
        }
    }

    .site-search__button {
        position: absolute;
        top: 0;
        right: 0;
        width: 3rem;
        height: 100%;
    }

    .header__search-trigger {
        position: relative;
        width: 2rem;
        height: 2rem;

        &.toggle--active {
            .site-search__trigger-search {
                @include set-invisible();
            }
        }

        &:not(.toggle--active) {
            .site-search__trigger-close {
                @include set-invisible();
            }
        }
    }

        .site-search__trigger-icon {
            @include vertical-align(true);
        }

        .site-search__trigger-close {
            padding: .25rem;
            border-radius: $input-border-radius;
            background-color: $color-grey-2;
        }

.site-search__suggestions-container {
    z-index: 3;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
}

    .site-search__suggestions-list {
        width: 100%;
        max-height: 65vh;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        border-radius: 0 0 .125rem .125rem;
        border-top: none;
        background-color: $color-white;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }

        .site-search__suggestions-section {
            &:not(:last-child) {
                margin-bottom: 1.25rem;
            }
        }

            .site-search__suggestions-title {
                margin-bottom: .5rem;
            }

            .site-search__suggestions-item {
                &:not(:last-child) {
                    margin-bottom: .75rem;
                }
            }

                .site-search__suggestions-image-wrap {
                    overflow: hidden;
                    @include aspect-ratio(1, 1, true);
                }

                .site-search__suggestions-image {
                    max-height: none;
                    @include vertical-align(true);
                }

                .site-search__suggestions-banner {
                    margin-bottom: .5rem;
                }

@include media-query(small-up) {
    .site-search__form {
        top: calc(100% + 1rem);
        right: 0;
        left: 0;
        max-width: 42rem;
        margin: auto;
    }

    .site-search__suggestions-container {
        min-width: 21.875rem;
    }

        .site-search__field {
            .set--has-results & {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
}

@include media-query(small) {
    .site-search__form {
        top: 100%;
    }

        .site-search__field {
            border-radius: 0;
        }
}
