.product-tile__wishlist {
    @include set-invisible();
    @include absolute(top 0 right 0);

    display: flex;
    padding: 1rem .75em;
    border-radius: 50%;
    overflow: hidden;

    .set--state-swipeable & {
        @include set-visible();
    }

    &.set--in-wishlist {
        .product-tile__wishlist-add {
            display: none;
        }

        .product-tile__wishlist-remove {
            display: block;
        }
    }
}

    .product-tile__wishlist-remove {
        display: none;
    }


@include media-query(medium-up) {
    .product-tile:hover {
        .product-tile__wishlist{
            @include set-visible();
        }
    }
}
