

.minicart__checkout-action {
    &:not(:first-child) {
        margin-top: 1rem;
    }

    &.button--apple-pay {
        &, &:hover, &:active {
            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }
}

@include media-query(small-up) {
    .header__minicart-overlay {
        width: 26rem;
    }
}

@include media-query(small) {
    .header__minicart-overlay {
        width: 85%;
    }
}
