@import "~app_accelerator_core/components/header/headerSearch";

    .site-search__container {
        @include relative;
        background: $white;
    }

        .site-search__field {
            background: $color-grey-1;
            border-radius: .625rem;
            padding: .6rem 1.875rem .6rem 2.5rem;
            font-size: $font-size-deci;
        }

        .site-search__button {
            right: auto;
            left: .75rem;
            width: 1rem;
            height: 1rem;
            top: 50%;
            transform: translateY(-50%);
            font-size: $font-size-deci;
        }

        .site-search_scan {
            @include absolute(right .625rem top 50%);

            height: 1.1875rem;
            transform: translateY(-50%);
        }

        .clear-search-input {
            @include absolute(right 2.2rem top 50%);

            svg {
                width: 0.625rem;
            }

            background-color: $color-grey-14;
            width: 1.125rem;
            height: 1.125rem;
            border-radius: 50%;
            transform: translateY(-50%);
        }

@include media-query(medium) {
    .site-search {
        margin-top: 1.25rem;
        z-index: 2;
        position: relative;
    }

        .site-search__form {
            position: static;
            width: 100%;
        }

            .site-search__field {
                font-size: $font-size-base;
            }
}

@include media-query(medium-up) {
    .site-search__form {
        max-width: none;
    }
}

@include media-query(medium-up) {
    .site-search__form {
        position: static;
        width: 20.5rem;
    }
}

@include media-query(xmedium-up) {
    .site-search__form {
        width: 34.5rem;
    }
}

@include media-query(large-up) {
    .site-search__form {
        width: 34.5rem;
    }
}

@include media-query(xlarge-up) {
    .site-search__form {
        @include absolute(left 50% right auto top 0);

        transform: translateX(-50%);
    }
}

.set--has-search-results {
    overflow: hidden;

    .header__nav-secondary,
    .header__hamburger-menu,
    .header-promo {
        display: none;
    }

    .header__logo-container {
        z-index: 5;
    }

    .header__logo {
        margin: 0 auto;
    }

    .site-search {

        &.set--has-results {
            z-index: 3;
            position: fixed;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            top: rem(25px);

            &:before {
                content: "";
                position: fixed;
                left: 0;
                right: 0;
                bottom: 0;
                top: 20%;
                background: black;
                opacity: 0.5;
            }

            .site-search__form {
                width: 100%;
            }

            .site-search__container {
                padding: rem(20px);

                @include media-query(medium-up) {
                    padding: 0;
                }
            }

            .site-search__control {
                position: relative;
                margin: 0 auto;

                &:after {
                    content: "";
                    position: absolute;
                    background: $white;
                    width: rem(500px);
                    right: 0;
                    left: 100%;
                    bottom: 0;
                    top: 0;
                    display: block;
                }

                @include media-query(medium-up) {
                    max-width: none;
                    width: 20.5rem;
                }


                @include media-query(xmedium-up) {
                    width: 40rem;
                }
            }

            .site-search__suggestions-corrections,
            .site-search__suggestions-phrases {
                margin: 0 auto;
                font-size: rem(14px);

                @include media-query(medium-up) {
                    max-width: none;
                }

                @include media-query(medium-up) {
                    width: 20.5rem;
                }

                @include media-query(xmedium-up) {
                    width: 40rem;
                }
            }

            .site-search__suggestions-corrections {
                margin-bottom: rem(30px);
                padding-left: rem(50px);
                .text-highlight {
                    font-weight: $font-weight-bold;
                  }
                .site-search__suggestions-correction {
                    margin-right: rem(7);
                }
            }

            .site-search__suggestions-phrases {
                padding-left: rem(45px);

                @include media-query(medium-up) {
                    display: flex;
                    gap: 40px;
                }

                .site-search__suggestions-section {
                    margin: rem(10px) 0 rem(24) rem(10px);
                    flex-grow: 1;
                }
            }

            .site-search__suggestions-list {
                padding-bottom: 75px;
                display: block;

                &.has-product-suggestion {
                    max-height: 100vh;

                    @include media-query(medium) {
                        max-height: calc(100vh - 175px); // Make sure we do not include header height
                    }
                }
            }

            .site-search__suggestions-title {
                font-size: rem(14px);
                text-transform: uppercase;
                text-wrap: nowrap;
            }

            .site-search__suggestions-section--category {
                @include media-query(medium-up) {
                    order: 2;
                }

                .text-highlight {
                  font-weight: 600;
                }
            }

            .site-search__suggestions-section--popular-searches {
                @include media-query(medium-up) {
                    order: 1;
                }
            }

            .site-search__suggestions-section--recent {
                @include media-query(medium-up) {
                    order: 3;
                }
            }

            .site-search__suggestions-item {
                padding-right: 1rem;

				.site-search__suggestions-name {
					white-space: nowrap;
				}

                .product-tile__anchor {
                    .product-add__button.add-to-cart,
                    .quick-add-trigger,
                    .product-add__button.product-add__button--no-size {
                        min-height: 0;
                        max-height: rem(24);
                        text-transform: none;
                        letter-spacing: rem(1);
                        font-size: $font-size-micro;
                    }
                }

                @include media-query(medium) {
                    padding-left: 0;
                    padding-right: 0;
                    width: 49%;
                }
            }

            .site-search__suggestions-matches--products {
                .slick-track {
                    margin-left:0;
                }
                @include media-query(medium) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
                @include media-query(medium-up) {
                    padding: 0 3rem;
                    margin-bottom: rem(75px);
                }
            }

            .site-search__close {
                position: absolute;
                right: rem(20px);
                z-index: 1;
                font-size: rem(16px);
                top: rem(-100px);
                background-color: $color-white;

                @include media-query(medium-up) {
                    top: -22px;
					padding-right: 1rem;
                    font-size: rem(12px);
                }

                &:hover {
                    text-decoration: underline;
                }
            }

            .site-search__all-results-cta {
                margin: 0 auto;
                min-width: rem(200px);
                width: max-content;
            }

        }
    }
}

