.inline-prompt-container {
    position: relative;
}

.inline-prompt {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($color-panel-background, 0.05);
    color: $color-body-copy;
    white-space: normal;
    transition: opacity $transition-speed-faster $transition-ease-out,
                visibility $transition-speed-faster $transition-ease-out;

    &:not(.toggle--active) {
        @include set-invisible();
    }
}

    .inline-prompt__body {
        max-width: 20rem;
    }

    .inline-prompt__footer {
        margin-top: 1rem;
    }
