@import "~app_accelerator_core/components/product/swatches";

.swatch--color {
    border: 0.0313rem solid $color-grey-28;

    &::after {
        @include absolute(top -.125rem left -.125rem top -.125rem bottom -.125rem);
        
        border-width: .0625rem;
        box-shadow: none;
    }

    &.selected {
        pointer-events: none;
    }

    span {
        margin-top: 3rem;
    }
    &:not(.disabled):hover, &.selected {
        &:after {
            box-shadow: none;
        }
        .swatch__icon--color {
            box-shadow: 0 0 0 rem(2) white, 0 0 0 rem(2.88) $color-grey-5;
        }
    }
}

.refinement__color {
    .swatch--color {
        &:not(.disabled):hover, &.selected {
            &:after {
                box-shadow: none;
            }
            box-shadow: 0 0 0 rem(2) white, 0 0 0 rem(2.88) $color-grey-5;
        }
    }
}

.swatch--color-small {
    height: .9375rem;
    width: .9375rem;
    flex: 0 0 .9375rem;
    
    &::after {
        @include absolute(top -.125rem left -.125rem top -.125rem bottom -.125rem);
    }

}

.swatch--color-large {
    width: 1.75rem;
    height: 1.75rem;
    flex: 0 0 1.75rem;
}

.swatch--color-medium {
    width: rem(24);
    height: rem(24);
    flex: 0 0 rem(24);
}
