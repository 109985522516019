html {
    //Certain modals contain forms,
    //which are known to cause scrolling issues on iOS devices
    &.cancel-scroll--force {
        overflow: hidden;
        height: 100%;
    }
}

body {
    .cancel-scroll &{
        overflow: hidden;
    }

    .cancel-scroll--force & {
        overflow: hidden;
        height: 100%;
    }
}

.window-modal {
    z-index: 10000; //Nothing should be higher than this.
    overflow: auto;
    display: flex;
    // justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparentize($color-black, 0.5);
    -webkit-overflow-scrolling: touch;
    transition-property: opacity, visibility, background-color;
    //Fixes IE-specific issue when using margin: auto alignment with flex (part 1).
    -ms-flex-align: stretch;

    &.modal-active {
        transition-timing-function: $transition-ease-out;
        transition-duration: $transition-speed-fast;
    }

    &:not(.modal-active) {
        pointer-events: none;
        transition-timing-function: $transition-ease-in;
        transition-duration: $transition-speed-fast;
        @include set-invisible();
    }

    &.modal-in-background {
        z-index: 9999;
        background-color: transparent;
        pointer-events: none;
    }
}

    .window-modal__content {
        // z-index: 1;
        position: relative;
        width: 100%;
        min-height: 6.25rem;
        background-color: $color-panel-background;
        transition-property: top, bottom, right, left, height, transform, opacity, visibility;
        transition-timing-function: $transition-ease-in;
        transition-duration: $transition-speed-fast;
        //Fixes IE-specific issue when using margin: auto alignment with flex (part 2).
        -ms-flex-item-align: baseline;

        box-shadow: 0 .25rem 2rem transparentize($color-black, .75);

        .modal-active & {
            transition-duration: $transition-speed-fast;
            transition-timing-function: $transition-ease-out;
        }

        > .modal-container--static {
            display: block;
        }
    }

    .window-modal__close {
        z-index: 10;
        position: absolute;
        top: 0;
        right: 0;
        height: 2.5rem;
        width: 2.25rem;

        &:hover {
            opacity: .5;
        }

        &:after {
            content: url(get-icon(close, $color-body-copy));
            width: 0.875rem;
            height: 0.875rem;
            display: inline-block;
        }
    }

/*------------------------------------*\
  #UTILITY-MODAL-CLASSES
\*------------------------------------*/
.modal-container--static {
    display: none;
}

.modal--full {
    .window-modal__content {
        max-width: none;
    }
}

.modal--flex {
    .window-modal__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}

.modal--hideable {
    &.modal-in-background {
        @include set-invisible();
    }
}

/*------------------------------------*\
  #GENERIC-MODAL
  Use these if you don't know what else to use.
\*------------------------------------*/

.modal__title {
    line-height: $font-line-height-centi;
}

.modal__description {
    margin-top: .75em;
    line-height: $font-line-height-deka;
}

.modal__content {
    margin-top: 2rem;
}

.modal__actions {
    margin-top: auto;
}

    .modal__action {
        margin-top: 2.5rem;
    }

.modal__scrollable {
    max-height: 17.5rem;
    padding-bottom: 1.25rem;
}

/*------------------------------------*\
  #SPECIAL-CASES
\*------------------------------------*/

.modal--quickview {
    .window-modal__content {
        max-width: 60rem;
    }
}

.modal--store-locator {
    .window-modal__content {
        max-width: 38rem;
        padding: 2rem;
    }
}

.modal--product-zoom {
    .window-modal__content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .window-modal__close {
        background-color: $color-panel-background;
    }
}

.modal--toast {
    pointer-events: none;
    background-color: transparent;
    color: $color-white;

    .window-modal__content {
        pointer-events: all;
        background-color: transparent;
        max-width: none;
        width: auto;
        height: auto;
        padding: .75rem 3rem .75rem 2rem;
        min-height: auto;
        margin-top: 1rem;
    }

    .window-modal__close {
        bottom: 0;
        margin: auto;

        &:after {
            content: url(get-icon(close, $color-white));
        }
    }
}

.modal--toast-error {
    .window-modal__content {
        background-color: $color-error;
    }
}

.modal--toast-success {
    .window-modal__content {
        background-color: $color-success;
    }
}

    .modal--toast__message {
        border-radius: 0.25rem;
    }


@include media-query(small-up) {
    body {
        .cancel-scroll--sm-up & {
            overflow: hidden;
        }
    }

    .window-modal__content {
        width: calc(100% - #{$gutter-outer-kilo});
        margin: auto;
        transform: scale(.95);
        border-radius: .25rem;

        @include set-invisible();

        .modal-active & {
            transition-delay: $transition-speed-faster;
            transform: scale(1);
            @include set-visible();
        }

        .modal-in-background & {
            transform: scale(.9) translateY(2rem);
        }
    }

    /*------------------------------------*\
      #GENERIC-MODAL
      Use these if you don't know what else to use.
    \*------------------------------------*/

    .modal--generic {
        .window-modal__content {
            max-width: 29rem;
            min-height: 21.25rem;
            padding: 1rem 1.5rem;
        }
    }

    /*------------------------------------*\
      #SPECIAL-CASES
    \*------------------------------------*/

    .modal--small {
        .window-modal__content {
            max-width: 48rem;
            min-height: 21.25rem;
            padding: 1rem 1.5rem;
        }
    }

    .modal--medium {
        .window-modal__content {
            max-width: 64rem;
            min-height: 21.25rem;
            padding: 2rem 2.5rem;
        }
    }

    .modal--large {
        .window-modal__content {
            max-width: 98rem;
            min-height: 21.25rem;
            padding: 2rem 2.5rem;
        }
    }

    .modal--page {
        padding: 2rem;

        .window-modal__content {
            max-width: map-get($breakpoints, large);
            min-height: 21.25rem;
        }
    }

    .modal--prompt {
        .window-modal__content {
            max-width: 26rem;
            min-height: 16rem;
            padding: 2rem;
        }
    }

    .modal--auth {
        padding: 2rem;
    }
}

@include media-query(small) {
    html {
        &.cancel-scroll {
            overflow: hidden;
            height: 100%;
        }
    }

    .window-modal__content {
        top: 100%;
        margin-top: auto;

        .modal-active & {
            top: 0;
        }

        .modal-in-background & {
            transform: translateY(55%);
        }
    }

    /*------------------------------------*\
      #GENERIC-MODAL
      Use these if you don't know what else to use.
    \*------------------------------------*/

    .modal--generic {
        .window-modal__content {
            min-height: 20rem;
            padding-top: 1rem;
            padding-right: 1rem;
            padding-left: 1rem;
            padding-bottom: 3.75rem;
        }
    }

    /*------------------------------------*\
      #SPECIAL-CASES
    \*------------------------------------*/

    .modal--prompt {
        .window-modal__content {
            min-height: 16rem;
            padding-top: 2rem;
            padding-right: 1.25rem;
            padding-left: 1.25rem;
            padding-bottom: 3.75rem;
        }
    }

    .modal--toast {
        .window-modal__content {
            margin-left: auto;
            margin-right: auto;
            margin-top: auto;
            margin-bottom: 1rem;
        }
    }

    /*------------------------------------*\
      #UTILITY-MODAL-CLASSES
    \*------------------------------------*/

    .modal--small-full {
        background-color: $color-panel-background;

        .window-modal__content {
            min-height: 100%;
            padding-bottom: 3.75rem;
        }
    }
}
