.product-tile--default { 
    height: 100%;
    .product-tile__promotion-container {
        min-height: rem(16);
    }

    .product-promotions {
        display: flex;
        flex-direction: column;
    }
    
        .product-promotions__item {
            font-weight: $font-weight-semibold;
            font-size: $font-size-deci;
            text-transform: uppercase;
            letter-spacing: 0.0437rem;
            color: $color-red-3;
            margin: .25rem .5rem 0;
            line-height: 1;
        }
}


@include media-query(small) {
    .product-tile--default {
        .product-promotions__item {
            font-size: $font-size-centi;
        }
    }
}