
@supports (-webkit-appearance: -apple-pay-button) {
    .adyen-checkout__applepay__button {
        display: inline-block;
        -webkit-appearance: -apple-pay-button;
        text-indent: -9999px;
    }
    .adyen-checkout__applepay__button--black {
        -apple-pay-button-style: black;
    }
    .adyen-checkout__applepay__button--white {
        -apple-pay-button-style: white;
    }
    .adyen-checkout__applepay__button-white-with-line {
        -apple-pay-button-style: white-outline;
    }
}

.adyen-checkout__applepay__button {
    border: 1px solid;
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: rem(5);
    padding: 0;
	margin-top: rem(1);
    box-sizing: border-box;
    min-width: auto;
    max-height: rem(34);
    background-image: url('../images/applepay.png');
    width: 100% !important;
}
.adyen-checkout__applepay__button--black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
    color: white;
}
.adyen-checkout__applepay__button--white {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
}
.adyen-checkout__applepay__button-white-with-line {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
    border: .5px solid black;
}
