@import "~app_accelerator_core/pages/product/productCommons";

.product-attribute__list {
    .product-attribute--type-anchor,
    .product-attribute--type-swatch {
        .product-attribute__contents {
            margin: .3125rem -.3125rem 0 0;
        }
    }
}
