@import "~app_accelerator_core/components/product/productLineItems";

.product-line-item {
    padding-top: 0;
    padding-bottom: 0;

    &:not(:last-child) {
        border-bottom: 0;
    }
}

.wishlist__items {
    .product-line-item--wishlist {
        .product-line-item__header {
            min-height: 2.5rem;

            .product-line-item__name {
                text-wrap: wrap;
            }
        }
    }
}

.product-line-item--wishlist {
    .product-line-item__header {
        font-weight: 500;
        letter-spacing: 0.0269rem;
        margin-bottom: 0;
    }

    .product-line-item__main {
        margin-bottom: rem(58);
        
        .product-line-item__actions {
            display: flex;
            flex-direction: row;
            margin-bottom: rem(20px);
        }
        
        .product-line-item__name {
            @include media-query(medium-up) {
                text-overflow: ellipsis;
                text-wrap: nowrap;
                overflow: hidden;
                display: block;
            }
        }
        
        .product-line-item__image-wrap {
            position: relative;
        }
    }

    .product-line-item__subheader {
        letter-spacing: 0.0225rem;
        color: $color-grey-10;
        margin-bottom: 0.75rem;
    }

    .product-line-item__attributes {
        letter-spacing: 0.0225rem;
        line-height: 1.125rem;

        .product-line-item__attribute-key {
            color: $color-grey-10;
        }

        .product-line-item__attribute-value {
            margin-left: 0.625rem;

            &--size {
                margin-left: 1.15rem;
            }
        }

    }

    .attribute-hidden {
        visibility: hidden;
    }

    .product-line-item__attributes {
        height: 2.5rem;
    }

    .product-line-item__qty-pricing {
        justify-content: flex-start;
        font-size: $font-size-centi;
        text-align: center;
        margin-top: 0;

        .product-line-item__quantity {
            flex-direction: row;
            text-align: left;
            line-height: 1.125rem;
            margin-bottom: .25em;

            .form-control-label {
                width: 2.5rem;
                margin: 0;
                font-size: $font-size-centi;
                color: $color-grey-10;
            }

            .quantity {
                margin-left: .25rem;
            }
        }

        .product-line-item__unit-price {
            flex-direction: row;
            text-align: left;

            .form-control-label {
                width: 2.5rem;
                margin: 0;
                font-size: $font-size-centi;
                color: $color-grey-10;
                text-transform: capitalize;

            }
        }

    }
    
    .price {
        .price__original + .price__sales {
            &.sales {
                font-weight: $font-weight-medium;
                color: $color-red-1;
            }
            &:has(+ .price__promo) {
                font-weight: $font-weight-normal;
                color: $color-primary;

                .value {
                    text-decoration: line-through;
                }
            }

            & + .price__promo {
                color: $color-red-1;
                font-weight: $font-weight-medium;
            }
        }

        .price__promo {
            color: $color-red-1;
            font-weight: $font-weight-medium;
        }

    }

    .product-line-item__promotions-sale {
        color: $color-red-1;
        font-weight: $font-weight-semibold;
        font-size: $font-size-centi;
        line-height: 1rem;
        margin-left: rem(6px);
        letter-spacing: 0.36px;
    }

    .product-line-item__promotion {
        flex-direction: row;
            text-align: left;
            margin-top: 0.125rem;
            min-height: 2rem;

            .form-control-label {
                width: 2.5rem;
                margin: 0;
                font-size: $font-size-centi;
                color: $color-grey-10;
                text-transform: capitalize;
            }

            .product-line-item__promotions {
                color: $color-red-1;
                font-weight: $font-weight-semibold;
                font-size: $font-size-centi;
                margin-left: 0.5rem;
            }
    }

    .product-line-item__actions {
        font-weight: 500;
        letter-spacing: $font-spacing--medium;
        margin-top: auto;

        @include media-query(medium) {
            display: flex;
            flex-direction: row;
            margin-top: 0;

            .product-line-item__action:last-child {
                margin-left: 1.2rem;
            }
        }
    }

    .product-line-item__footer {
        margin-top: 0;

        .product-line-item__footer--addToCart {
            .button {
                background-color: $color-pink;
                color: $color-white;
                border: 0;
                letter-spacing: $font-spacing--medium;
            }
        }

        .button {
            max-width: 11.625rem;
            min-height: 1.875rem;
            max-height: 1.875rem;
            padding-top: 0.375rem;
            letter-spacing: $font-spacing--medium;
            font-size: $font-size-centi;
            font-weight: 500;
        }
    }

    .product-line-item__main.sold-out {
        color: $color-grey-10;

        .product-line-item__edit,
        .product-line-item__header {
            pointer-events: none;
            cursor: not-allowed;
        }

        .product-line-item__remove {
            color: $color-primary;
        }

        .button {
            background-color: $color-grey-14;
            border: 0;
            color: $color-white;
            pointer-events: none;
            cursor: not-allowed;
        }

        .price__sales {
            color: $color-grey-10;
        }

        .product-line-item__image {
            display: block;
            object-fit: cover;
            background: $color-primary-transparent;
            pointer-events: none;
        }
        
        .product-line-item__sold-out-msg {
            display: block;
            margin-top: rem(10px);
            font-size: rem(12px);
            letter-spacing: $font-spacing--medium;
            color: $color-red-1;
        }
    }
}

.form-intro__title {
    letter-spacing: 0.0275rem;
}

.product-line-item__wishlist-revert--msg {
    letter-spacing: 0.0225rem;
}
