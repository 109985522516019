
.hidden {
    display: none !important;
}

.sr-only,
.visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.set--visible {
    @include set-visible();
}

.set--invisible {
    @include set-invisible();
}

//When all you need is opacity.
.set--no-opacity {
    opacity: 0;
}

.pointer--none {
    pointer-events: none;
}

.toggle-display--hidden {
    &:not(.toggle--active) {
        display: none;
    }
}

/*------------------------------------*\
  #VIEWPORT VISIBILITY
\*------------------------------------*/
@include media-query(small-up) {
    .display--small-only {
        display: none !important;
    }
}

@include media-query(medium-up) {
    .display--medium-only,
    .display--medium-down {
        display: none !important;
    }
}

@include media-query(medium) {
    .display--medium-up {
        display: none !important;
    }
}

@include media-query(small-up-to-medium) {
    .display--hide-medium {
        display: none !important;
    }
}

@include media-query(small) {
    .display--small-up,
    .display--medium-only {
        display: none !important;
    }
}
