@import "~app_accelerator_core/components/links";

.link {
    &:hover {
       opacity: 1;
    }
}

.link--primary {
    &:hover, &:focus {
        text-decoration: underline;
    }
}

.link--underline:hover {
    text-decoration: none;
}
