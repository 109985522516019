
.product-gallery {

}

.product-gallery--pdp {
    width: 100%;

    .product-gallery__button {
        cursor: zoom-in;
    }
}

.product-gallery--quickview {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    margin-left: .625rem;
    margin-right: 1.25rem;
}

    .product-gallery__aspect-ratio {
        @include aspect-ratio(1, 1.25, true);
    }

    .product-gallery__img {
        max-height: none;
    }

.product-gallery-thumbnails--quickview {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    margin-left: 1.25rem;
}

    .product-gallery-thumbnails__item {
        position: relative;

        &:not(:last-child) {
            margin-bottom: .625rem;
        }

        &.slick-nav--active {
            .product-gallery-thumbnails__img {
                outline: .0625rem solid $color-black;
                outline-offset: -.0625rem;
            }
        }
    }

    .product-gallery-thumbnails__button {
        display: block;
    }
