
.product-attribute__list {
    //Remove bottom margin to offset the difference added by the individual
    //.product-attribute elements.
    margin-bottom: -1.75rem;

    //This specifity is intentional so that it is easier to separate
    //different types of "product-attribute__list" container.
    //For example, one may prefer to have a different layout for QuickAdd, Bundles, etc.
    .product-attribute {
        margin-bottom: 1.75rem;
    }

    .product-attribute--qty {
        max-width: 6.375rem;
    }

    .product-attribute--type-anchor,
    .product-attribute--type-swatch {
        .product-attribute__contents {
            margin: -.3125rem;
        }
    }

        .product-attribute__swatch,
        .product-attribute__anchor {
            margin: .3125rem;
        }
}

    .product-availability__label {
        margin-right: .25em;
    }

.product-promotions__item {
    word-break: break-word;
}

.product-pickup {
    margin-top: 1rem;
}

    .product-pickup__selected {
        padding-left: 1.9em;
        margin-top: .5rem;
    }

    .product-pickup__selected-action {
        margin-top: .75rem;
    }

.product-common__product-details,
.product-common__secondary-actions {
    margin-top: 1rem;
}

.product-common__social {
    position: relative;
}

    .product-common__social-item {
        &:not(:last-child) {
            margin-right: 1.0375rem;
        }
    }

.product-add__button {
    &.button--apple-pay {
        &, &:hover, &:active {
            &:not(:first-child) {
                margin-top: 1rem;
            }
        }
    }
}
