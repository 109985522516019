$border-color: $color-grey-4;
$primary-color: $color-grey-3;
$border-radius: $input-border-radius;

@import 'slim-select/src/slim-select/slimselect';

.ss-main {
    &.form-control {
        height: $input-height;
        padding-left: 0;
        padding-right: 0;
    }

    .ss-single-selected {
        height: 100%;
        padding-left: $input-lateral-offset;
        padding-right: $input-lateral-offset;
    }
}
