
.promobar {
	  position: relative;
	  border-top: rem(1) solid $color-white;
    border-bottom: rem(1) solid $color-white;
    .promo-banner {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		&:last-child {
			display: none;
			&.slick-slide {
				display: block;
			}
		}
		
	  .details-view {
		padding-left: rem(15);
	  }
    }

}

.promobar-inner {
	.promo-banner-list__item {
		line-height: rem(102);
		position: relative;
		&.slick-vertical,
		&.slick-slide {
			line-height: rem(102);

		}

		i {
			margin: 0 0.5em;
		}
		.button1_html-open {
			padding-left: rem(10);
		}
	}
}
// INFO MODAL
.asset-info-modal {
	.button1_html,
	.button2_html {
		&-btn,
		&-open {
			text-transform: none;
			transition: all .2s;
			opacity: .3;
			cursor: default;
			position: relative;
			display: inline-flex;
			transform-origin: center;
			transform: scale(.8);
			text-decoration: underline;
		}
	}
	&.asset-info-modal--active {
		.button1_html,
		.button2_html {
			&-btn,
			&-open {
				opacity: 1;
				cursor: pointer;

				&.active,
				&:focus, 
				&:hover {
					transform: scale(.9);
				}
			}
		}
	}
}
.window-modal.asset-info-modal--popup {
	.window-modal__content {
		position: absolute;
		top: clamp(100px, 15vw, 160px);
		box-shadow: 0 0 10rem -2rem;
		max-width: rem(600);
		padding: 1.5rem 0 .5rem 0;
		right: 0;
    	left: 0;
		@include media-query(medium) {
			margin: 0 auto;
			max-width: rem(370);
		}
	}
	.window-modal__close {
		top: 0;
		right: 0;
	}
	.asset-info-modal--content,
	.asset-info-modal--content {
		width: rem(600);
    	max-width: 100%;
		max-height: calc(100vh - 15vw - 100px - 3rem);
		overflow-y: auto;
		padding: 0rem 1.5rem 1rem 1.5rem;
		font-size: .8em;
    	line-height: 1.3;
		text-align: center;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-size: $font-size-h5;
		}
		p {
			font-size: rem(11);
		}
	}
}
.anim-button {
	background-color: transparent;
    height: rem(20);
    width: rem(20);
    position: absolute;
    top: 50%;
    right: rem(6);
    transform: translateY(-50%);
    outline: none;
    
    svg:first-child {
    	  display: block;
    }
    svg:last-child {
    	  display: none;
    }
    @include media-query(small) {
    	  height: rem(15);
    	  width: rem(15);
    }

    &.stop {
		    box-sizing: border-box;
		    svg:first-child {
    	      display: none;
	      }
	    svg:last-child {
	    	  display: block;
	    }
    }
}