@import "~app_accelerator_core/base/typography";

.font-family--primary {
    font-family: $font-family-primary;
}

.font-family--secondary {
    font-family: $font-family-secondary;
    line-height: 1;
    text-transform: uppercase;
}

.font-size--deci {
    font-size: $font-size-deci;
}

h1 {
    font-size: $font-size-h1;
}

h2 {
    font-size: $font-size-h2;
    font-weight: $font-weight-medium;
}

h3 {
    font-size: $font-size-h3;
    font-weight: $font-weight-medium;
}

h4 {
    font-size: $font-size-h4;
    font-weight: $font-weight-medium;
}

h5 {
    font-size: $font-size-h5;
    font-weight: $font-weight-medium;
}

h6 {
    font-size: $font-size-h6;
    font-weight: $font-weight-medium;
}

b,
strong,
.font-weight--semibold,
.font-weight--bold {
    font-weight: $font-weight-semibold;
}

.font-weight--medium {
    font-weight: $font-weight-medium;
}

.cms-generic-copy {
    font-size: $font-size-deci;

    a {
        text-decoration: underline;
        font-weight: $font-weight-medium;

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    ul,
    ol {
        list-style-position: inside;
        padding-left: 0;
        margin-bottom: 1.5em;
    }

    ul,
    ol,
    p+p {
        margin-top: 1.5em;
    }

    table {
        border-collapse: collapse;
        table-layout: fixed;
        width: 100%;
    }

    th,
    td {
        border: solid $color-black;
        border-width: 0 0 1px 0;
        padding: 0.5rem;
        text-align: left;
        font-size: $font-size-deci;
        line-height: 1.5em;

        &:first-child {
            border-left: 0;
            padding-left: 0;
        }

        &:last-child {
            border-right: 0;
        }
    }

    tr {
        border-spacing: 0
    }

    thead th {
        background-color: transparent;
        padding: .5rem;
        text-align: left;
        border: 0;
        border-bottom: 1px solid $color-black;
        font-weight: $font-weight-normal;
    }

    tbody td,
    tbody th {
        border-bottom: 1px solid $color-black;
        border-right: 1px solid $color-black;
        padding: .1875rem .5rem;
        white-space: nowrap;
        font-size: $font-size-deci;
    }
}