@import "~app_accelerator_core/modules/productList";


.product-grid__item {
    margin-bottom: 4.3125rem;
}

@include media-query(small) {
    .product-grid__item {
        margin-bottom: 2rem;
    }
}