
.product-grid__item {
    margin-bottom: 2.25rem;
}

.product-grid {
    &:not(.set--show-all-products) {
        .product-grid__item--hideable {
            display: none;
        }
    }
}

    .product-grid__cta-more {
        &.set--show-all-products {
            .product-grid__cta-label--show {
                display: none;
            }
        }

        &:not(.set--show-all-products) {
            .product-grid__cta-label--hide {
                display: none;
            }
        }
    }
