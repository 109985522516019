
.icon-tile {
    position: relative;
    padding: 1.25rem;
    font-size: $font-size-h1;
    @include media-query(small) {
        margin: rem(3) 0;
        font-size: $font-size-deka;
    }
    &:hover {
        background-color: var(--background-color-hover) !important;
    }

}

.icon-tile__description {
    font-size: $font-size-hecto;
    @include media-query(small) {
        margin: rem(3) rem(23.5);
        font-size: $font-size-deci;
    }
}

    .icon-tile__media {
        display: inline-block;
        max-width: 10rem;
        height: 3rem;
    }

    .icon-tile__content {
        // margin-top: .75rem;
    }

    .icon-tile__actions {
        margin-right: -.25rem;
        margin-left: -.25rem;
    }

        .icon-tile__cta {
            margin-right: .25rem;
            margin-left: .25rem;

            &:not(:last-child) {
                margin-bottom: .75rem
            }
        }


@include media-query(small-up) {
    .icon-tile__description {
        margin-top: .5em;
    }

    .icon-tile__actions:not(:first-child) {
        margin-top: 1rem;
    }
}

@include media-query(small) {
    .icon-tile__description {
        margin-top: .35rem;
    }

    .icon-tile__actions:not(:first-child) {
        margin-top: .825rem;
    }
}
