.footer__copyright-additional {
    .footer-links__list {
        display: flex;
        justify-content: space-between;
    }

    .link {
        font-size: $font-size-centi;
        font-weight: $font-weight-medium;
        letter-spacing: $font-spacing--large;
    }
}

    .footer-links__heading {
        letter-spacing: $font-spacing--large;
    }

.footer__social {
    margin-top: 2rem;
}

.footer-links__item:lang(fr) {
	margin-left: 2rem;
}

@include media-query(medium) {
    .footer-links__heading--toggleable {
        padding: 1.25em 0;
        font-size: $font-size-deci;
        font-weight: $font-weight-medium;
        letter-spacing: $font-spacing--medium;

        &.toggle--active {
            font-weight: $font-weight-semibold;
        }
    }

    .footer-links__heading-arrow {
        position: relative;
        transition: transform $transition-speed-fast $transition-ease-bezier;

        .toggle--active & {
            transform: rotate(180deg);
        }
    }

    .footer-links__list--stacked {
        &:not(.toggle--active) {
            display: none;
        }
    }

        .footer__info-group {
            border-bottom: 1px solid $color-grey-4;
            margin: 0 -1rem;
            padding: 0 1.875rem;

            &:last-child {
                border: 0;
            }
        }

        .footer__main-content {
            .footer-links__list {
                padding: 0 0 1.5rem 1.5rem;
            }

            .link {
                font-size: $font-size-deci;
                display: block;
                line-height: 1.75rem;
                padding: .25rem 0;
            }
        }

    .footer__copyright-additional {
        .footer-links__list {
            padding: 2.25rem 1rem 0;
        }
    }

    .footer__social {
        text-align: center;
    }
}

@include media-query(medium-up) {
    .footer__main-content {
        .link {
            font-size: $font-size-deci;
            line-height: 1.79;
            letter-spacing: $font-spacing--large;
        }

        .footer-links__list {
            margin-top: 1rem;
        }
    }

    .footer__payments-icon {
        margin-right: .1875rem;

        .icon {
            width: 1.875rem;
        }
    }

    .footer__copyright-additional {
        margin-left: auto;
        min-width: 16.25rem;
        max-width: 20%;

		&:lang(fr) {
			max-width: 30%;
		}
    }
}

@include media-query(xmedium-up) {
    .footer__payments-icon {
        margin-right: .1875rem;

        .icon {
            width: 1.875rem;
        }
    }
}

