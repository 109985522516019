
.experience-component.experience-accelerator_recommenders-einsteinCarousel {
    .einsten-recommender {
        margin-left: 3.75rem;
        margin-right: 3.75rem;
    }

    .module-container__header {
        display: block;
        width: 100%;

        .module-container__title {
            font-family: $font-family-primary;
            font-size: 2.125rem;
            text-transform: none;
        }
    }
}

.einsten-recommender {
    position: relative;
}

    .einsten-recommender__carousel {
        min-height: 6.25rem;
    }
