    .subscription-banner__description {
        letter-spacing: $font-spacing--large;
    }

    .subscription-banner__form {
        margin-top: .75rem;
        display: flex;
        justify-content: space-between;
    }

        .subscription-banner__field {
            font-size: $font-size-centi;
        }

        .subscription-banner__button {
            min-width: 4.375rem;
            flex: 0 0 4.375rem;
            margin-left: .3125rem;
        }

		.subscription-banner__button:lang(fr) {
            min-width: 4.375rem;
            flex: 0 0 4.375rem;
            margin-left: .3125rem;

			button {
				width: 6rem;
			}
        }

        .subscription-banner__input {
            flex-grow: 1;

            .form-control-label {
                width: auto;
            }

            .subscription-banner__field {
                min-height: 2.2rem;
            }
        }

@include media-query(medium-up) {

}
