@keyframes ssc-loading {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(100%);
    }
}

%ssc-animation {
    display: block;
    background-color: $skeleton-element-color;
    position: relative;
    overflow: hidden;

    &:after {
        content: "";
        animation: ssc-loading $skeleton-loading-animation-time infinite;
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(-100%);
        z-index: 1;
        background: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.3), transparent);
    }
}

.ssc {
    cursor: progress;
    user-select: none;
}

    .ssc-wrapper {
        padding: $skeleton-wrapper-padding;
    }

        .ssc-card {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: $skeleton-card-border-radius;
            background-color: $skeleton-card-background-color;
        }

        .ssc-circle {
            border-radius: 50%;
            width: $skeleton-circle-size;
            height: $skeleton-circle-size;

            @extend %ssc-animation;
        }

        .ssc-hr {
            width: 100%;
            height: $skeleton-hr-height;

            @extend %ssc-animation;
        }

        .ssc-line {
            border-radius: $skeleton-line-border-radius;
            width: 100%;
            height: $skeleton-line-height;

            @extend %ssc-animation;
        }

        .ssc-head-line {
            border-radius: $skeleton-line-border-radius;
            width: 100%;
            height: $skeleton-headline-height;
            display: flex;
            align-items: center;

            @extend %ssc-animation;

            &.set--w-50 {
                width: 50%;
            }
        }

        .ssc-square {
            width: 100%;
            height: 15rem;

            @include media-query(medium-up) {
                height: $skeleton-square-height;
            }

            @extend %ssc-animation;
        }

/*

SOURCE - https://www.cssscript.com/animated-skeleton-loading-screen/

<div class="ssc">
    <div class="ssc-card ssc-wrapper">
        <div class="flex mbs">
            <div class="tag ssc-square"></div>
            <div class="tag ssc-square"></div>
            <div class="tag ssc-square"></div>
            <div class="tag ssc-square"></div>
            <div class="tag ssc-square"></div>
        </div>
    </div>
    <br />
    <div class="ssc-hr"></div>
    <br />
    <div class="ssc-card ssc-wrapper">
        <div class="ssc-head-line w-50"></div>
        <br />
        <div class="flex align-start justify-between">
            <div class="ssc-card ssc-wrapper w-30">
                <div class="ssc-head-line mb"></div>
                <div class="ssc-line w-80 mbs"></div>
                <div class="ssc-line w-40 mbs"></div>
                <div class="ssc-line w-60 mbs"></div>
            </div>
            <div class="ssc-card ssc-wrapper w-30">
                <div class="ssc-head-line mb"></div>
                <div class="ssc-line w-70 mbs"></div>
                <div class="ssc-line w-30 mbs"></div>
                <div class="ssc-line w-90 mbs"></div>
            </div>
            <div class="ssc-card ssc-wrapper w-30">
                <div class="ssc-head-line mb"></div>
                <div class="ssc-line w-60 mbs"></div>
                <div class="ssc-line w-90 mbs"></div>
                <div class="ssc-line w-30 mbs"></div>
            </div>
        </div>
    </div>
    <br />
    <div class="ssc-hr"></div>
    <br />
    <div class="flex align-start">
        <div class="ssc-card w-30 mr">
            <div class="ssc-wrapper flex justify-between">
                <div class="w-60 mr">
                    <div class="ssc-line mb w-50"></div>
                    <div class="ssc-line mb w-70"></div>
                    <div class="ssc-line w-90"></div>
                </div>
                <div class="ssc-circle" style="width: 70px; height: 70px"></div>
            </div>
            <div class="ssc-hr"></div>
            <div class="ssc-wrapper">
                <div class="flex align-center justify-between">
                    <div class="ssc-head-line w-60 mr"></div>
                    <div class="ssc-line w-30"></div>
                </div>
            </div>
            <div class="ssc-hr"></div>
            <div class="ssc-wrapper">
                <div class="ssc-line w-100 mb"></div>
                <div class="ssc-line w-70 mb"></div>
                <div class="ssc-line w-30 mb"></div>
                <div class="ssc-line w-80 mb"></div>
                <div class="ssc-line w-60 mb"></div>
            </div>
            <div class="ssc-hr"></div>
            <div class="ssc-wrapper">
                <div class="ssc-line w-100 mb"></div>
                <div class="ssc-line w-70 mb"></div>
                <div class="ssc-line w-30 mb"></div>
                <div class="ssc-line w-80 mb"></div>
                <div class="ssc-line w-60 mb"></div>
            </div>
            <div class="ssc-hr"></div>
            <div class="ssc-wrapper">
                <div class="ssc-line w-100 mb"></div>
                <div class="ssc-line w-70 mb"></div>
                <div class="ssc-line w-30 mb"></div>
                <div class="ssc-line w-80 mb"></div>
                <div class="ssc-line w-60 mb"></div>
            </div>
        </div>
        <div class="w-100">
            <div class="ssc-card ssc-wrapper">
                <div class="ssc-head-line"></div>
                <br />
                <div class="ssc-square"></div>
            </div>
            <br />
            <div class="ssc-card ssc-wrapper">
                <div class="ssc-head-line"></div>
                <br />
                <div class="ssc-square"></div>
            </div>
            <br />
            <div class="ssc-card ssc-wrapper">
                <div class="ssc-head-line"></div>
                <br />
                <div class="ssc-square"></div>
            </div>
        </div>
    </div>
</div>
 */
