@include media-query(medium) {
    .header-flyout__container {
        .staticViewAll {
            padding: 0 1.25rem;
        }
        .level-2 {
            .menu-horizontal {
                padding: rem(10) 0 0;
                .sub-menu-list {
                    a {
                        font-size: $font-size-h5;
                        line-height: 2.5;
                        letter-spacing: rem(.4);
                    }
                    
                }
                .content-asset {
                    display: none;
                }
            }
        }
    }
    .header-flyout {
        .header-flyout__item {
            padding: 1rem 0;
            .header-flyout__anchor {
                padding: 0 1.25rem;
            }
        }
    }
}

@include media-query(medium-up) {
    .menu-horizontal {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        padding: 1.5rem 0;
        > li {
            &.sub-menu-list {
                margin-bottom: 1.25rem;
                font-size: $font-size-h5;
                max-width: rem(340);
                flex-basis: 25%;
                flex-grow: 1;
                padding: 0 rem(16) 0 0;

                .has-submenu {
                    font-size: $font-size-h5;
                    font-weight: $font-weight-semibold;
                    line-height: 1;
                    letter-spacing: rem(.7);
                    text-transform: uppercase;
                    padding: 0em 0.5em;
					margin: 14px 0px 12px;
                    display: block;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                
            }
        }
        li {
            .level-3 {
                padding-left: 0;
                margin-bottom: 1em;
                .has-submenu {
                    margin-bottom: 0;
                    font-weight: $font-weight-normal;
                    text-transform: capitalize;
                }
                li {
                    &:first-child {
                        display: none;
                    }
                    a {
                        padding: 0.5rem;
						line-height: 1;
                        display: inline-block;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }

        .title-list {
            margin-bottom: 1.25rem;
            font-size: $font-size-h5;
            max-width: rem(340);
            flex-basis: 25%;
            flex-grow: 1;
            padding: 0 rem(16) 0 0;
            a {
                font-size: $font-size-h5;
                font-weight: $font-weight-semibold;
                padding: 0.5em;
                display: inline-block;
                text-transform: uppercase;
                &:hover {
                    text-decoration: underline;
                }
            }
        }

		.header-flyout__anchor.has-submenu {
			font-weight: $font-weight-semibold;
		}
    }

    
}

.menu-horizontal {
    > li {
        &.sub-menu-list {
            @include media-query(medium-up) {
                display: none;
            }

            &.display-categories {
                display: block;

            }
        }
    }

    .sub-menu-list {
        .badge {
            display: inline-block;
            padding: rem(2) rem(6);
            font-size: $font-size-micro;
            font-weight: $font-weight-medium;
            line-height: 1;
            white-space: nowrap;
            vertical-align: text-bottom;
            border-radius: 0.15rem;
            color: $color-white;
            margin-left: rem(2) ;
        }
    } 
}
