/* BEGIN LIVE CHAT CSS - added by Zac Harris, September 2021 - GY-2023 */
.product-detail .attribute.amount .icon-ico-drop-down:hover ~ .swatches,
.product-detail .attribute.amount .swatches:hover,
.product-detail .attribute.size .icon-ico-drop-down:hover ~ .swatches,
.product-detail .attribute.size .swatches:hover {
    z-index: 999;
}

.embeddedServiceLiveAgentStateChatInputFooter
    .footerMenuWrapper
    .footer-menu
    .slds-button,
.footerMenuWrapper,
.embeddedServiceSidebarMinimizedDefaultUI .minimizedText > .message,
.helpButtonLabel,
.embeddedServiceHelpButton .helpButton .uiButton:focus::before,
.embeddedServiceHelpButton .helpButtonDisabled {
    display: none !important;
}

.embeddedServiceHelpButton .helpButton button.uiButton {
    background-color: $color-white;
    font-family: "Arial", sans-serif;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
    outline: 1px solid #f4d3d6;
}

.sidebarHeader[embeddedService-chatHeader_chatHeader] {
    display: block;
    text-align: center;
    padding-top: 12px;
}

.embeddedServiceSidebar {
    font-family: "Graphik Medium" !important;
}

button[embeddedService-chatHeader_chatHeader] {
    min-height: 14px;
    min-width: 14px;
    height: 14px;
    width: 14px;
    margin-top: -2px;
    float: left;
    background-color: #f9f9f9;
    border-radius: 3px;
    padding: 2px;
}

button.closeButton[embeddedService-chatHeader_chatHeader] {
    float: right;
    margin-top: 0px;
}

embeddedservice-chat-header lightning-icon {
    margin-top: -3px;
    display: block;
}

.embeddedServiceSidebarForm .backgroundImg {
    background-size: 300px 100px;
    background-color: white;
}

h2[embeddedService-chatHeader_chatHeader] {
    padding-top: 15px;
    display: inline;
    color: black;
    text-transform: initial;
    font-size: 10pt;
    font-family: "Graphik Medium";
}

.embeddedServiceSidebarFormField .uiInput .uiLabel-left {
    text-transform: initial;
}

.embeddedServiceSidebar .assistiveText {
    display: none;
}

.embeddedServiceLiveAgentQueuePosition .queuePositionChatIcon {
    transform: scale(2);
    margin: 30px;
}

.sidebarBody button {
    color: white !important;
    background-color: black !important;
    text-align: center;
    text-decoration: none !important;
    font-family: "Graphik Semibold" !important;
    display: inline-block;
    cursor: pointer;
}

.embeddedServiceLiveAgentStateChatInputFooter .chasitorText {
    border: 0;
}

.embeddedServiceSidebarButton:not(:disabled):hover,
.sidebarBody button:hover {
    background-color: darkgrey !important;
}

.embeddedServiceSidebarButton .label {
    display: inline;
}

.embeddedServiceHelpButton .helpButton .uiButton,
.embeddedServiceHelpButton .helpButton .uiButton:hover::before,
.embeddedServiceHelpButton .helpButton .uiButton:hover {
    background-color: #fff;
    box-shadow: none;
}

.embeddedServiceSidebarMinimizedDefaultUI.helpButton svg:hover {
    fill: white;
}

.embeddedServiceLiveAgentStateWaiting .waitingStateButtonContainer button {
    color: white;
}

.embeddedServiceLiveAgentStateChat .endChatContainer .endChatButton {
    margin-bottom: 12px;
}

.embeddedServiceIcon.x-small svg {
    width: 30px;
    height: 30px;
    fill: #000;
    transform: scaleX(-1);
}

.embeddedServiceSidebarMinimizedDefaultUI .embeddedServiceIcon {
    width: 30px;
    height: 30px;
    margin-left: -8px;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
    outline: 0;
}

.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.chasitor.plaintextContent {
    color: black;
}

.embeddedServiceSidebarMinimizedDefaultUI.helpButton .messageContent {
    display: inline;
}

.embeddedServiceHelpButton .helpButton .uiButton:hover,
.embeddedServiceSidebarMinimizedDefaultUI .content:hover {
    background-color: #000;
}

.label {
    text-transform: initial;
    margin-right: 0;
}

.fa-angle-up:before {
    color: #111;
}

.fa-angle-up:hover::before,
.embeddedServiceSidebarButton.uiButton--inverse .label {
    color: white;
}

img[embeddedService-chatHeader_chatHeader],
.embeddedServiceSidebarMinimizedDefaultUI .queuePositionNumber {
    display: none;
}

.embeddedServiceSidebarMinimizedDefaultUI img {
    display: none;
}

.embeddedServiceSidebarMinimizedDefaultUI,
.embeddedServiceSidebarMinimizedDefaultUI .content {
    width: 40px !important;
    height: 40px !important;
    min-width: 40px !important;
    background-size: 26px 26px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../icons/chat.svg");
    box-shadow: none;
    border: 1px solid #f0f0f0 !important;
    margin: -1px;
    background-color: white;
}

.embeddedServiceHelpButton .helpButton button.uiButton {
    position: absolute;
    background-size: 26px 26px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../icons/chat.svg");
    min-width: 40px !important;
    width: 40px !important;
    height: 40px !important;
    cursor: pointer;
    right: 3px;
    box-shadow: none;
    border: 1px solid #f0f0f0 !important;
    margin: -1px;
}

.embeddedServiceHelpButton .helpButton .uiButton:hover,
.embeddedServiceSidebarMinimizedDefaultUI:hover,
.embeddedServiceSidebarMinimizedDefaultUI .content:hover {
    background-image: url("../icons//chat-white.svg");
    background-color: black;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

.newMessage.embeddedServiceSidebarMinimizedDefaultUI .minimizedText {
    border: 1px solid white;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    background-color: #7cfc00;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    position: absolute;
    right: -4px;
    top: 0px;
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

.embeddedServiceHelpButton .embeddedServiceIcon::before,
.embeddedServiceIcon svg {
    display: none !important;
}

.embeddedServiceLiveAgentQueuePosition .queuePositionChatIcon svg {
    display: inline !important;
    transform: scaleX(-1);
}

.embeddedServiceHelpButton .helpButton .uiButton,
.embeddedServiceSidebarMinimizedDefaultUI,
.embeddedServiceHelpButton .helpButton .uiButton:hover::before {
    border-radius: 50% !important;
    margin-left: 0;
}

.embeddedServiceHelpButton .helpButton,
.embeddedServiceSidebarMinimizedDefaultUI.helpButton,
.embeddedServiceSidebarMinimizedDefaultUI {
    bottom: 46px !important;
    right: 21px !important;
}

.embeddedServiceSidebarForm .backgroundImg {
    background-image: url("/on/demandware.static/-/Sites-ardene-Library/default/vad582bf09ffdec0582480f951617d30a3a3cea63/footer/PreChatHeaderEnglish.jpg") !important;
}

button.chatActionButton.Send.medium.uiButton--default.uiButton.embeddedServiceLiveAgentStateChatAction,
.embeddedServiceLiveAgentStateChatInputFooter
    .embeddedServiceLiveAgentStateChatAction.Remove,
.embeddedServiceLiveAgentStateChatInputFooter
    .chasitorControls
    .chatActionButton {
    background-color: transparent !important;
}

.embeddedServiceIcon svg {
    display: inline-block !important;
    fill: #333;
}

.embeddedServiceLiveAgentStateChatAction.uiButton:disabled svg,
.embeddedServiceLiveAgentStateChatAction.uiButton svg {
    fill: #333;
    margin-left: 8px;
}

.embeddedServiceLiveAgentStateChatAction.uiButton svg,
.embeddedServiceLiveAgentStateChatInputFooter
    .embeddedServiceLiveAgentStateChatAction:hover.Upload
    svg {
    fill: black;
}

.embeddedServiceLiveAgentStateChatInputFooter
    .embeddedServiceLiveAgentStateChatAction.Upload {
    background-color: #ededf1 !important;
}

.embeddedServiceLiveAgentStateChatInputFooter
    .chasitorControls
    .chatActionButton {
    padding: 0;
}

.embeddedServiceLiveAgentStateChatInputFooter .chatActionButton.Send {
    padding: 13px 8px 0 0;
}

@media only screen and (max-width: 48em) {
    .embeddedServiceSidebarMinimizedDefaultUI .embeddedServiceIcon {
        margin-left: -6px;
        margin-top: 2px;
    }

    .embeddedServiceSidebarMinimizedDefaultUI.helpButton,
    .embeddedServiceSidebarMinimizedDefaultUI,
    .embeddedServiceHelpButton .helpButton {
        bottom: 60px !important;
        right: 12px !important;
    }

    .embeddedServiceHelpButton .helpButton .uiButton {
        bottom: 0px !important;
        right: 0px !important;
    }
}

/* END LIVE CHAT CSS */