
.link, .link--primary {
    transition: opacity $transition-speed-faster $transition-ease-default;

    &:hover {
        opacity: .7;
    }
}

.link--primary {
    display: inline-block;
    font-weight: $font-weight-semibold;

    .component-actions__cta-icon {
        stroke: currentColor;
        stroke-width: .125rem;
        font-size: .75em;
        vertical-align: middle;
    }
}

.link--underline {
    text-decoration: underline;
}

.link--underline-hover {
    &:hover, &:focus {
        text-decoration: underline;
    }
}

.link--highlight-hover {
    &:hover, &:focus {
        color: $color-primary;
    }
}

.link--flex {
    display: inline-flex;
}

/**
 *  Utility classes for buttons and pseudo buttons
 */
.cursor--pointer:hover {
    cursor: pointer;
}

.cursor--not-allowed:hover {
    cursor: not-allowed;
}