.product-tile__swatches {
    @include absolute(bottom -2rem left 0 right 0);

    background: $color-white;
    justify-content: center;
    z-index: 1;

	&.set--state-expanded {
		right: rem(-26);
	}
}

    .product-tile__swatches-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 11.875rem;
        transform: scaleY(-1);
    }

        .product-tile__swatch {
            margin: 0 .5rem .9375rem;
        }


        .product-tile__swatches-icon--less {
            display: none;

            .set--state-expanded & {
                display: flex;
            }
        }

        .product-tile__swatches-icon--more {
            .set--state-expanded & {
                display: none;
            }
        }

@include media-query(medium) {
    .product-tile__swatches-container {
        direction: rtl;
        justify-content: flex-end;
    }

        .product-tile__swatch {
            margin: 0 .8rem .9375rem;
        }

        [data-swatch-component="toggle"] {
            order: -1;
        }
}
