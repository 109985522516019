@import "~app_accelerator_core/modules/quickView";

.modal--quickview {
    .window-modal__content {
        padding: rem(70px) rem(20px) rem(40px) rem(20px);
        max-width: 47.1rem;
        
        @include media-query(medium-up) {
            padding: rem(40px);
        }
    }
    
    .quickview__main {
        @include media-query(medium-up) {
            padding: 0 0 0 rem(40px);
        }
    }
    
    .quickview__name {
        font-size: rem(18px);
        margin-bottom: rem(10px);
        font-weight: 500;
        line-height: 1.11;
        letter-spacing: rem(0.9px);
        
    }
    
    .price {
        font-size: rem(18px);
        letter-spacing: rem(0.9px);
			.price__original + .price__sales {
				&.sales {
					font-weight: $font-weight-medium;
					color: $color-red-1;
				}
				&:has(+ .price__promo) {
					font-weight: $font-weight-normal;
					color: $color-primary;
	
					.value {
						text-decoration: line-through;
					}
				}
	
				& + .price__promo {
					color: $color-red-1;
					font-weight: $font-weight-medium;
				}
			}
	
			.price__promo {
				color: $color-red-1;
				font-weight: $font-weight-medium;
			}
    }
	
    .line-item-total-price-amount {
        color: $color-red-1;
        font-weight: $font-weight-semibold;
    }

    .quickview__promotions {
        .product-promotions__item {
            color: $color-red-1;
            font-weight: $font-weight-semibold;
            font-size: $font-size-centi;
            letter-spacing: $font-spacing--medium;
        }
    }
    
    .product-attribute {
        padding: rem(22px) 0;
    }
    .quickview__attribute-list {
        border-top: 1px solid $color-grey-2;
    }
    
    .product-attribute--qty {
        display: none;
        gap: 20px;
        align-items: baseline;
        
        .form-control--select {
            min-width: rem(50px);
        }
    }
    .product-attribute {
        border-bottom: 1px solid $color-grey-2;
        margin-bottom: 0;
    
        &:last-child {
            border-bottom: none;
        }
    }
    
    .product-attribute__label {
        font-size: rem(12px);
        margin-bottom: rem(15px);
    }
    
    .product-attribute__dismiss,
    .quickview__availability {
        display: none;
    }
    
    .product-attribute__anchor {
        height: rem(30px);
        width: rem(30px);
    }
    
    .window-modal__close {
        top: rem(10px);
        right: rem(10px);
    }
    
    .product-gallery--quickview {
        margin: 0;
        max-width: rem(275);
        max-height: rem(413);
        @include media-query(medium) {
            width: rem(275);
            height: auto;
            margin: 0 auto;
            .slick-prev {
                left: rem(-54);
            }
            .slick-next {
                right: rem(-54);
            }
        }
    }
    
    .quickview-show-thumbnails {
        .product-gallery-thumbnails--quickview {
            margin: 0 rem(10px) 0 0;
        }
    }
    .quickview__attribute-list {
        .product-attribute  {
            .product-attribute__label {
                font-weight: $font-weight-medium;
            }
        }
    }
    .product-attribute__size-chart {
        display: none;
    }
    .quickview__footer {
        .product-add__container {
            .product-add__button {
                border-radius: 0.2rem;
            }
        }
    }
	.quickview {
		.product-common__number,
		.product-common__product-details {
			display: none;
		}
	}
}