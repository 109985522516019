
.consent-notification {
    z-index: 100;
    position: fixed;
    width: calc(100% - 2.5rem);
    bottom: 1.25rem;
    left: 0;
    right: 0;
    border: solid .0625rem;
    border-radius: .25rem;
    box-shadow: 0 .5rem 1.25rem transparentize($color-black, .75);
    transform: translateY(0);

    transition-property: opacity, visibility, transform;
    transition-duration: $transition-speed-fast;
    transition-timing-function: $transition-ease-out;

    &:not(.toggle--active) {
        transform: translateY(100%);
        @include set-invisible();
    }
}

@include media-query(small-up) {
    .consent-notification {
        padding: 1rem;
    }
}

@include media-query(small) {
    .consent-notification {
        padding: 1rem 1rem 2rem;
    }
}
