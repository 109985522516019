.alert-danger {
    background: $color-black;
    color: $color-white;
    border: 0;
    border-radius: 0;
    text-align: center;
    font-size: $font-size-centi;

    .close {
        display: none;
    }
}
