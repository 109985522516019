.minicart-modal {
    background-color: transparent;
}

.minicart-modal .window-modal__content {
    width: 23.4375rem;
    position: absolute;
    top: 8.75rem;
    right: 2rem;
    border-radius: 0.375rem;
    border: 0.0625rem solid $color-grey-3;

    .window-modal__close {
        display: none;
    }
}

.minicart-modal__header {
    background-color: $color-grey-13;
    padding:  0.3125rem 1.625rem;
}

.minicart-modal__title {
    font-size: $font-size-h4;
}

.minicart-modal__product-info {
    padding:  0.3125rem 1.625rem;
    margin: 1rem 0;
}

.minicart-modal__product-name {
    font-size: $font-size-h5;
    line-height: normal;
}

.minicart-modal__product-info .minicart-modal__product-attributes {
    padding-left: 1rem;
}

.minicart-modal__product-attribute-list {
    margin-top: 0.3125rem;
    .price,
    .price__promo {
        font-size: $font-size-h6;

    }
    .product-line-item__promotions {
        color: $color-red-1;
        font-weight: $font-weight-medium;
    }
}

.minicart-modal__product-attribute {
    text-align: left;
    font-size: $font-size-h6;
    &:not(:first-child) {
        margin-top: .25em;
    }
    span{
        display: table-cell;
        text-align: left;
    }
}

@include media-query(small) {
    .minicart-modal {
        background-color: rgba(0,0,0,0.5);
    }

    .minicart-modal .window-modal__content {
        width: 188px;
        height: 161px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding: 2.5rem 0.3125rem;
        text-align: center;
        border-radius: 6px;

        .product-added-notification__msg {
            font-size: $font-size-h4;
        }

        .bag-icon {
            position: relative;
            display: inline-block;

            .header__minicart-icon {
                font-size: rem(50px);

                @include media-query(medium-up) {
                    display: none;
                }
            }

            .check-icon {
                position: absolute;
                left: 50%;
                top: 55%;
                transform: translate(-50%,-50%);
            }
        }
    }
}
